import { Component, OnInit } from '@angular/core';

import { Global } from 'src/app/services/global.service';
import { Config } from 'src/app/models/env-vars.model';
import { API } from 'src/app/services/api.service';
import {AppData} from "../../services/appdata.service";
import { alertService } from '../../services/alert-service.service';

@Component({
  selector: 'app-ecostruxure-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class EcostruxureRedirectComponent implements OnInit
{

	constructor(
		private global: Global,
		private api: API,
		private appData: AppData,
		private alertService: alertService
	)
	{
		Config.hide_navbar = true;
	}

	ngOnInit(): void
	{
		let access_token: any = this.appData.get('ecostruxureState');

        this.api.get("/ecostruxure/oauth/access-token/?code=" +  this.appData.get('ecostruxureCode')).subscribe(
            data =>
            {
				this.appData.delete('ecostruxureState');
				this.appData.delete('ecostruxureCode');
                window.location.href = window.location.origin + "/login_middleware/?" + "authorization_token=" + access_token + "&next=ecostruxure-select-project";
            },
            error =>
            {
				this.alertService.alert('error', error.error, 5)
				this.appData.delete('ecostruxureState');
				this.appData.delete('ecostruxureCode');
				this.global.goToApp("home");
            }
        );
	}

}
