import { Component, OnInit } from '@angular/core';

import { AppData } from '../../../services/appdata.service';
import { API } from '../../../services/api.service';
import { Global } from '../../../services/global.service';
import { alertService } from 'src/app/services/alert-service.service';
import { Config } from '../../../../../src/app/models/env-vars.model';

import * as moment from 'moment';

@Component({
    selector: 'app-certifications',
    templateUrl: './certifications.component.html',
    styleUrls: ['./certifications.component.css']
})
export class CertificationsComponent implements OnInit {

    constructor(
        public global: Global,
        private api: API,
        public appData: AppData,
        private alertService: alertService
    ) { }

    config = Config;
    total_score: any = 0;
    loader: boolean = true;
    cert_count_since_last_month: any = 0;
    cert_eligible_text: string = "";
    review_data: any = [];
    filtered_data: any = [];
    review_submitted_on: string = "";
    under_review_text: string = "";
    mycertifications: any = [];
    need_review_type_drp: boolean = false;
    review_type: string = '';
    review_btn_txt: string = '';
    last_month_date: any = null;
    maxima: any = {};
    scores: any = {};
    cert_rating_system: string = '';

    request_loader: boolean = false;
    requested_leed_id: string = '';
    requested_cert_type: string = 'LEED Online';
    requested_leed_id_error: boolean = false;
    request_button: string = "Submit";
    request_submission_text: string = "";
    request_submission_error: boolean = false;
    request_submitted: boolean = false;

    registration_loading: boolean = false;
    registration_RS: any = '';
    registration_RS_error: boolean = false;
    register_btn_text: string = 'Register';
    anticipated_date_error: boolean = false;
    sign_cert_agreement: boolean = false;
    agreement_error: boolean = false;
    registration_status: string = 'not_applicable';
    sanctioned_country: boolean = false;
    registration_edit_mode: boolean = false;
    registration_id: any = null;
    registration_date: any = null;

    downloadKey(data: any) {
        var url = this.config.basic_api_url + '/assets/LEED:' + this.appData.get('leed_id') + '/review/snapshot/?access-token=' + this.appData.get('token') + '&subscription-key=' + this.config.sub_key;
        var name = data.name + ".zip";
        var a = document.createElement('a');
        var linkText = document.createTextNode(name);
        a.appendChild(linkText);
        a.title = name;
        a.href = url;
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
    };

    removeDuplicates(originalArray: any[], prop: any) {
        var newArray = [];
        var lookupObject: any = {};
        for (var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    };

    getIconFromScore(score: any) {

        if (40 <= score && score <= 49) {
            return "../../../assets/images/logo/LEED_Certified.svg";
        } else if (50 <= score && score <= 59) {
            return "../../../assets/images/logo/LEED_Silver.svg";
        } else if (60 <= score && score <= 79) {
            return "../../../assets/images/logo/LEED_Gold.svg";
        } else if (80 <= score) {
            return "../../../assets/images/logo/LEED_Platinum.svg";
        }
        else {
            return "../../../assets/images/logo/Non_LEED.svg";
        }
    };

    showCertificationInfo(obj: any) {
        if (obj.energy_score || obj.water_score || obj.waste_score || obj.transportation_score || obj.human_experience_score) {
            return true;
        }
        else {
            return false;
        }
    };

    showCertificationDetail(obj: any) {
        if ($("#cert_img_" + obj.id).attr('src') == 'assets/images/arrow_down.svg') {
            $("#cert_score_info_" + obj.id).remove();
            $("#cert_" + obj.id).removeClass("extend_cert_list");
            $("#cert_img_" + obj.id).attr("src", "assets/images/arrow_left.svg");
        }
        else {
            var total_score = 0;
            if (obj.energy_score || obj.water_score || obj.waste_score || obj.transportation_score || obj.human_experience_score) {
                var energy_score = 0;
                var water_score = 0;
                var waste_score = 0;
                var transportation_score = 0;
                var human_experience_score = 0;
                var base_score = 0;

                if (obj.energy_score) {
                    energy_score = obj.energy_score;
                }
                if (obj.water_score) {
                    water_score = obj.water_score;
                }
                if (obj.waste_score) {
                    waste_score = obj.waste_score;
                }
                if (obj.transportation_score) {
                    transportation_score = obj.transportation_score;
                }
                if (obj.human_experience_score) {
                    human_experience_score = obj.human_experience_score;
                }
                if (obj.base_score) {
                    base_score = obj.base_score;
                    if (base_score > 10) {
                        base_score = parseInt((base_score / 10).toString());
                    }
                }

                total_score = energy_score + water_score + waste_score + transportation_score + human_experience_score + base_score;

                var auth_token = this.appData.get('token');
                var sub_key = this.config.sub_key;

                var div = "";
                div += "<div class='col-md-12 pl0 pr0 mb25 pb20 border_lrb border_bottom_shadow' id='cert_score_info_" + obj.id + "'>";
                div += "<div class='col-md-6 pl0 divider_right'>";
                div += "<iframe id='cert_score_bar_'" + obj.id + " frameborder='0' src='" + this.config.basic_api_url + '/assets/LEED:' + this.appData.get('leed_id') + '/overview/projectcertification/?access-token=' + auth_token + '&subscription-key=' + sub_key + '&certification_only=true&score=' + total_score + "' width='100%' height='155px'></iframe>";
                div += "</div>";
                div += "<div class='col-md-6 pl30'>";
                div += "<div class='cert_score_item vertical_center_flex'>";
                div += "<div class='cert_score_category vertical_center_flex'>";
                div += "<img class='mr7' src='assets/images/icons/energy.svg'>";
                div += "<span>Energy</span>";
                div += "</div>";
                div += "<div class='cert_reading'>";
                div += "<span class='value'>" + energy_score + "</span>";
                div += "<span class='cert_unit'> / 33</span>";
                div += "</div>";
                div += "</div>";
                div += "<div class='cert_score_item vertical_center_flex'>";
                div += "<div class='cert_score_category vertical_center_flex'>";
                div += "<img class='mr7' src='assets/images/icons/water.svg'>";
                div += "<span>Water</span>";
                div += "</div>";
                div += "<div class='cert_reading'>";
                div += "<span class='value'>" + water_score + "</span>";
                div += "<span class='cert_unit'> / 15</span>";
                div += "</div>";
                div += "</div>";
                div += "<div class='cert_score_item vertical_center_flex'>";
                div += "<div class='cert_score_category vertical_center_flex'>";
                div += "<img class='mr4 mln4' src='assets/images/icons/waste.svg'>";
                div += "<span>Waste</span>";
                div += "</div>";
                div += "<div class='cert_reading'>";
                div += "<span class='value'>" + waste_score + "</span>";
                div += "<span class='cert_unit'> / 8</span>";
                div += "</div>";
                div += "</div>";
                div += "<div class='cert_score_item vertical_center_flex'>";
                div += "<div class='cert_score_category vertical_center_flex'>";
                div += "<img class='mr4 mln4' src='assets/images/icons/transport.svg'>";
                div += "<span>Transportation</span>";
                div += "</div>";
                div += "<div class='cert_reading'>";
                div += "<span class='value'>" + transportation_score + "</span>";
                div += "<span class='cert_unit'> / 14</span>";
                div += "</div>";
                div += "</div>";
                div += "<div class='cert_score_item vertical_center_flex'>";
                div += "<div class='cert_score_category vertical_center_flex'>";
                div += "<img class='mr7' src='assets/images/icons/human.svg'>";
                div += "<span>Human Experience</span>";
                div += "</div>";
                div += "<div class='cert_reading'>";
                div += "<span class='value'>" + human_experience_score + "</span>";
                div += "<span class='cert_unit'> / 20</span>";
                div += "</div>";
                div += "</div>";
                div += "</div>";
                div += "</div>";
                $(div).insertAfter("#cert_" + obj.id);
                $("#cert_" + obj.id).addClass("extend_cert_list");
                $("#cert_img_" + obj.id).attr("src", "assets/images/arrow_down.svg");
            }
        }

    };

    getProgressBarUrl() {
        var auth_token = this.appData.get('token');
        var sub_key = this.config.sub_key;
        this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/scores/v2/').subscribe(
            data => {
                this.loader = false;
                if (this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community') {
                    this.maxima = {
                        "base": 100,
                        "energy": 100,
                        "water": 100,
                        "waste": 100,
                        "transport": 100,
                        "human_experience": 100,
                        "subscores_occupant": 100,
                        "subscores_co2": 100,
                        "subscores_voc": 100
                    };
                    this.scores = data;
                    this.cert_eligible_text = "This project does not meet the requirements of the LEED performance prerequisites. Project must achieve a minimum score of 40 to meet the requirements of LEED performance prerequisites.";
                    if ((this.scores.energy && this.scores.energy > 40) && (this.scores.water && this.scores.water > 40) && (this.scores.waste && this.scores.waste > 40) && (this.scores.transport && this.scores.transport > 40) && (this.scores.human_experience && this.scores.human_experience > 40)) {
                        this.cert_eligible_text = "This project may meet the requirements of the LEED performance prerequisites.";
                    }
                }
                else {
                    this.maxima = data.maxima;
                    this.scores = data.scores;
                    if (this.scores && this.scores.energy) {
                        this.total_score += this.scores.energy;
                    }
                    if (this.scores && this.scores.water) {
                        this.total_score += this.scores.water;
                    }
                    if (this.scores && this.scores.waste) {
                        this.total_score += this.scores.waste;
                    }
                    if (this.scores && this.scores.transport) {
                        this.total_score += this.scores.transport;
                    }
                    if (this.scores && this.scores.human_experience) {
                        this.total_score += this.scores.human_experience;
                    }
                    if (this.scores && this.scores.base) {
                        this.total_score += this.scores.base;
                    }
                    $('#cert_score_bar').attr('src', this.config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/overview/projectcertification/?access-token=" + auth_token + "&subscription-key=" + sub_key + "&certification_only=true&score=" + this.total_score);

                    if (40 <= this.total_score && this.total_score <= 49) {
                        this.cert_eligible_text = "This project may be eligible for LEED Certified certification.";
                    } else if (50 <= this.total_score && this.total_score <= 59) {
                        this.cert_eligible_text = "This project may be eligible for LEED Silver certification.";
                    } else if (60 <= this.total_score && this.total_score <= 79) {
                        this.cert_eligible_text = "This project may be eligible for LEED Gold certification.";
                    } else if (80 <= this.total_score) {
                        this.cert_eligible_text = "This project may be eligible for LEED Platinum certification.";
                    } else {
                        this.cert_eligible_text = "This project may not be eligible for a LEED Certification.";
                    }
                }
            },
            error => {
                this.loader = false;
                $('#cert_score_bar').attr('src', this.config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/overview/projectcertification/?access-token=" + auth_token + "&subscription-key=" + sub_key + "&certification_only=true&score=0");
            }
        );
    };

    getCertificationName() {
        this.registration_status = 'not_applicable';
        this.cert_rating_system = this.appData.get('project_rating_system');
        if ((this.appData.get('project_rating_system').toLowerCase() == "none" || this.appData.get('project_rating_system').toLowerCase() == "other") && !this.global.isNoneApplicableForRecert()) {
            if (this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school') {
                this.cert_rating_system = "LEED v4.1 O+M Certification";
            }
            else if (this.appData.get('project_type') == 'city') {
                this.cert_rating_system = "LEED v4.1 Cities Certification";
            }
            else if (this.appData.get('project_type') == 'community') {
                this.cert_rating_system = "LEED v4.1 Communities Certification";
            }
        }
        else if (this.config.config.settings.RS_V4_1.indexOf(this.appData.get('buildingData').rating_system) > -1 && !this.global.isLEEDCertified(this.appData.get('buildingData').certification)) {
            this.cert_rating_system = "LEED v4.1 O+M Certification";
        }
        else if (this.need_review_type_drp) {
            if (this.review_type == 'Certification') {
                if (this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school') {
                    this.cert_rating_system = "LEED v4 O+M certification";
                }
                else if (this.appData.get('project_type') == 'transit') {
                    this.cert_rating_system = "LEED v4 O+M: Transit certification"
                }
                else if (this.appData.get('project_type') == 'city') {
                    this.cert_rating_system = "LEED for Cities certification"
                }
                else if (this.appData.get('project_type') == 'community') {
                    this.cert_rating_system = "LEED for Communities certification"
                }
            }
            else if (this.review_type == 'Pre-Certification') {
                if (this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school') {
                    this.cert_rating_system = "LEED v4 O+M Pre-Certification";
                }
                else if (this.appData.get('project_type') == 'transit') {
                    this.cert_rating_system = "LEED v4 O+M: Transit Pre-Certification"
                }
                else if (this.appData.get('project_type') == 'city') {
                    this.cert_rating_system = "LEED for Cities Pre-Certification"
                }
                else if (this.appData.get('project_type') == 'community') {
                    this.cert_rating_system = "LEED for Communities Pre-Certification"
                }
            }

        }
        else {
            if (this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school') {
                if (this.appData.get('buildingData').score_code_version.name >= 3) {
                    this.cert_rating_system = "LEED v4.1 O+M recertification";
                }
                else {
                    this.cert_rating_system = "LEED v4 O+M recertification";
                }
                this.getCerRegistrationDetails();
            }
            else if (this.appData.get('project_type') == 'transit') {
                this.cert_rating_system = "LEED v4 O+M: Transit recertification"
            }
            else if (this.appData.get('project_type') == 'city') {
                this.cert_rating_system = "LEED for Cities recertification"
            }
            else if (this.appData.get('project_type') == 'community') {
                this.cert_rating_system = "LEED for Communities recertification"
            }
        }
    };

    getProfileURL() {
        this.loader = true;
        this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/usgbc-project-profile/').subscribe(
            data => {
                this.loader = false;
                var data = data;
                if ("text" in data) {
                    if (data.text.type == 'E') {
                        this.alertService.alert('error', data.text.message, 5);
                    }
                    else {
                        window.open(data.text.project_url, "_blank")
                    }
                }
            },
            error => {
                this.loader = false;
                this.alertService.alert('error', "Error retrieving URL", 5);
            }
        );
    };

    checkLEEDID() {
        this.request_submission_text = "";
        this.request_submission_error = false;
        if (this.requested_leed_id.trim() == '') {
            this.requested_leed_id_error = true;
        }
        else {
            this.requested_leed_id_error = false;
        }
    };

    openRequestForm() {
        this.request_loader = false;
        this.requested_leed_id = '';
        this.requested_cert_type = 'LEED Online';
        this.requested_leed_id_error = false;
        this.request_button = "Submit";
        this.request_submission_text = "";
        this.request_submission_error = false;
        this.request_submitted = false;
        $('#requestCertificationModal').modal("show");
    };

    requestCertification() {
        this.request_submission_text = "";
        this.request_submission_error = false;
        if (this.requested_leed_id.trim() == '') {
            this.requested_leed_id_error = true;
        }
        else {
            this.requested_leed_id_error = false;
            this.request_loader = true;
            this.request_button = "Submitting...";
            var data = { "leed_id": this.requested_leed_id, "certification_type": this.requested_cert_type }

            this.api.post('/assets/LEED:' + this.appData.get('leed_id') + '/request-to-add-certification/', data).subscribe(
                data => {
                    this.request_loader = false;
                    this.request_button = "Submit";
                    this.request_submitted = true;
                }, error => {
                    this.request_loader = false;
                    this.request_button = "Submit";
                    if (error.detail != undefined) {
                        this.request_submission_text = error.detail;
                    }
                    else {
                        this.request_submission_text = 'Unable to submit request.';
                    }
                    this.request_submission_error = true;
                }
            );
        }
    };

    makePayment(type: string, $event: any) {
        if ($event.target.nodeName == 'A' && type == 'recertification') {
            return;
        }
        if (type == 'recertification') {
            this.checkForPrePayment();
        }
        else if (type == 'climaterisk') {
            this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'climate-risk' });
        }
        else if (type == 'certificate') {
            this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'performance-certificates' });
        }
    };

    checkForPrePayment() {
        this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/review/').subscribe(
            data => {
                if (data.review_payment != undefined && data.review_payment[0].bypass_payment) {
                    $(".block_pre_pay").modal("show");
                }
                else {
                    this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'payment', 'type': 'recertification', 'initiator': 'certifications' });
                }
            },
            error => {
            }
        );
    };

    showRegistrationModal(edit: boolean){
        this.registration_edit_mode = edit;
        if (this.registration_edit_mode){
        }
        else{
            this.registration_RS = '';
            this.registration_RS_error = false;
            $('#anticipated_date').val('');
            this.anticipated_date_error = false;
            this.sign_cert_agreement = false;
            this.agreement_error = false;
            this.register_btn_text = 'Register';
        }
        $('#register_for_leed').modal('show');
    };

    showPreviousRegistrationModal(){
        $('#register_for_leed').modal('show');
    };

    moveToScoreVersion(){
        $('#score_version_notification').modal('hide');
        this.global.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'score-version' });
    };

    createCertRegistration() {
        if (this.registration_RS == ""){
            this.registration_RS_error = true;
        }
        if ($('#anticipated_date').val() == ""){
            this.anticipated_date_error = true;
        }
        if (!this.sign_cert_agreement){
            this.agreement_error = true;
        }
        if (this.registration_RS_error || this.anticipated_date_error || this.agreement_error){
            return;
        }
        this.registration_loading = true;
        this.register_btn_text = "Registering...";
        var calendar_date: any = $('#anticipated_date').val();
        var selected_date: any = calendar_date.split('/');
        var data = { "rating_system": this.registration_RS, "submittal_date": selected_date[2] + '-' + selected_date[0] + '-' + selected_date[1], "is_terms_accepted": this.sign_cert_agreement }
        this.api.post('/assets/LEED:' + this.appData.get('leed_id') + '/certifications/registration/', data).subscribe(
            data => {
                this.registration_loading = false;
                this.register_btn_text = "Register";
                this.registration_status = 'registered';
                this.cert_rating_system = data.rating_system;
                this.registration_id = data.id;
                this.registration_date = data.created_at;
                $('#register_for_leed').modal('hide');
                this.alertService.alert('success', 'Successfully registered', 5);
            }, error => {
                this.registration_loading = false;
                this.register_btn_text = "Register";
            }
        );
    };

    triggerAnticipatedDate() {
        (<any>$('#anticipated_date')).fdatepicker('show');
    };

    changeAnticipatedDate(){
        this.anticipated_date_error = false;
        if (this.registration_edit_mode){
            let calendar_date: any = $('#anticipated_date').val();
            let selected_date: any = calendar_date.split('/');
            this.registration_loading = true;
            this.api.put('/assets/LEED:' + this.appData.get('leed_id') + '/certifications/registration/' + this.registration_id + '/', { "submittal_date": selected_date[2] + '-' + selected_date[0] + '-' + selected_date[1] }).subscribe(
                data => {
                    this.registration_loading = false;
                    this.alertService.alert('success', 'Successfully updated', 5);
                }, error => {
                    this.registration_loading = false;
                }
            );
        }
    };

    updateRegistrationRS(value: string){
        this.registration_RS_error = false;
        if (value == this.registration_RS){
            return;
        }
        else if (this.appData.get('buildingData').score_code_version.name < 3 && value.indexOf('v4.1') > -1){
            $('#register_for_leed').modal('hide');
            $('#score_version_notification').modal('show');
            return;
        }
        this.registration_RS = value;
        if (this.registration_edit_mode){
            this.registration_loading = true;
            this.api.put('/assets/LEED:' + this.appData.get('leed_id') + '/certifications/registration/' + this.registration_id + '/', { "rating_system": this.registration_RS }).subscribe(
                data => {
                    this.registration_loading = false;
                    this.cert_rating_system = data.rating_system;
                    this.alertService.alert('success', 'Successfully updated', 5);
                }, error => {
                    this.registration_loading = false;
                }
            );
        }
    }

    getCerRegistrationDetails(){
        this.registration_loading = true;
        this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/certifications/registration/').subscribe(
            data => {
                this.registration_loading = false;
                if (data.length > 0 && data[0].active) {
                    this.registration_status = 'registered';
                    this.cert_rating_system = data[0].rating_system;
                    this.registration_id = data[0].id;
                    this.registration_RS = data[0].rating_system;
                    let temp_date: any = data[0].submittal_date;
                    this.registration_date = data[0].created_at;
                    let selected_date: any = temp_date.split('-');
                    $('#anticipated_date').val( selected_date[1] + '/' + selected_date[2] + '/' + selected_date[0] );
                }
                else{
                    this.registration_status = 'not_registered';
                }
            },
            error => {
                this.registration_loading = false;
                this.registration_status = 'not_registered';
            }
        );
    };

    updateAgreementStatus() {
        if (this.sign_cert_agreement){
            this.agreement_error = false;
        }
        else{
            this.agreement_error = true;
        }
    };

    ngOnInit(): void {
        this.config.cert_loader = false;

        this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/certifications/').subscribe(
            data => {
                if (data.certificates.length != 0) {
                    data.certificates = data.certificates;
                    data.certificates = data.certificates.sort(function (a: any, b: any) {
                        return new Date(a['date_certified']).getTime() - new Date(b['date_certified']).getTime()
                    });

                    for (var i = 0; i < data.certificates.length; i++) {
                        data.certificates[i].certification_expire = String(moment(data.certificates[i].certification_expire, "YYYY-MM-DD").format("MMM DD, YYYY")).toLowerCase() != 'invalid date' ? moment(data.certificates[i].certification_expire, "YYYY-MM-DD").format("MMM DD, YYYY") : 'N/A';
                        data.certificates[i].date_certified = String(moment(data.certificates[i].date_certified, "YYYY-MM-DD").format("MMM DD, YYYY")).toLowerCase() != 'invalid date' ? moment(data.certificates[i].date_certified, "YYYY-MM-DD").format("MMM DD, YYYY") : 'N/A';
                    }
                    this.mycertifications = data.certificates;
                }
            },
            error => {
            }
        );

        this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/review/snapshot/keys/').subscribe(
            data => {
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].document_metadata == null && data[i].doc_category == null && data[i].doc_description == null) {
                            var name = data[i].doc_key.split('/')[1];
                            if (data[i].doc_key.split('/')[1] != data[i].doc_key.split('/')[2]) {
                                name = data[i].doc_key.split('/')[1] + ' (' + data[i].doc_key.split('/')[2] + ')';
                            }
                            this.review_data.push({ "name": name, "last_modified": data[i].uploaded_at })
                        }
                    }
                }
                let temp_arr = this.removeDuplicates(this.review_data, "name");
                if (temp_arr.length > 0) {
                    this.filtered_data = temp_arr.slice(0, 1);
                }
            },
            error => {
            }
        );

        this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/review/').subscribe(
            data => {
                if ('review_data' in data && data['review_data'].length > 0) {
                    this.review_submitted_on = data['review_data'][0].created_at;
                    if (data['review_data'][0].review_payment && data['review_data'][0].review_payment['status'] != 'completed') {
                        if (data['review_data'][0].review_payment['material_desc'] == 'canada_invoice'){
                            this.under_review_text = "Review will start once the review payment is received. If you haven't received invoice, please contact us at LEEDcertification@cagbc.org";
                        }
                        else{
                            this.under_review_text = "Review will start once the review payment is received";
                        }
                    }
                    else {
                        this.under_review_text = "Project is under review";
                    }
                }
            },
            error => {
            }
        );

        if (!this.global.checkInCurrentRatingSystem()) {
            if (this.global.isLEEDCertified(this.appData.get('buildingData').certification)) {
                if (this.appData.get('buildingData').rating_system.toLowerCase() == 'none' || this.appData.get('buildingData').rating_system.toLowerCase() == 'other') {
                    if (this.global.isNoneApplicableForRecert()) {
                        this.review_type = "LEED Certification";
                    }
                }
                else {
                    this.review_type = "LEED Certification";
                }
            }
        }
        else {
            if (this.global.isLEEDCertified(this.appData.get('buildingData').certification)) {
                this.review_type = "LEED Certification";
            }
            else {
                if (this.global.isPreCertified(this.appData.get('buildingData').certification)) {
                    this.review_type = "Certification";
                }
                else {
                    this.review_type = "Certification";
                    this.need_review_type_drp = true;
                }
            }
        }

        var now = new Date()
        now.setMonth(now.getMonth() - 1);
        this.last_month_date = (now.getFullYear()) + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + (now.getDate())).slice(-2);

        this.api.get('/certification-count/?date=' + this.last_month_date).subscribe(
            data => {
                this.cert_count_since_last_month = data.count;
            },
            error => {
            }
        );

        this.getProgressBarUrl();
        this.getCertificationName();

        if ((this.appData.get('project_rating_system').toLowerCase() == "none" || this.appData.get('project_rating_system').toLowerCase() == "other") && !this.global.isNoneApplicableForRecert()) {
            this.review_btn_txt = "Register for LEED Certification";
        }
        else {
            this.review_btn_txt = "Submit for Review";
        }

        if (this.config.config.settings.NO_REVIEW_COUNTRY.indexOf(this.appData.get('buildingData').country) > -1 || this.config.config.settings.NO_REVIEW_COUNTRY.indexOf(this.appData.get('buildingData').manageEntityCountry) > -1){
            this.sanctioned_country = true;
        }

        var nowTemp = new Date();
        nowTemp.setDate(nowTemp.getDate() - 1);
        (<any>$('#anticipated_date')).fdatepicker({
            onRender: function (date: any) {
                return date.valueOf() < nowTemp.valueOf() ? 'disabled' : '';
            }
        }).on('changeDate', (ev: any) =>
        {
            this.changeAnticipatedDate();
        });

    }

}
