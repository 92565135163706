<div class="content-wrap">
	<div id="content" class="content">
        <main class="view-animate fade-up overflow_y_initial" role="main" data-ui-view="">
            <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
                <div class="align-center mt11 width-100-perc">
                    <h4 class="fw-500">Submit for review</h4>
                </div>
                <div class="pt20" (click)="global.goToApp('selected_project', { 'leed_id': appData.get('leed_id'), 'app_name': 'certifications'})">
                    <svg class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                </div>
            </nav>
            <div class="cert_selection_container pl20 pr20 pb100" [ngClass]="(recompute_data_lock || recompute_loading || registration_loading) ? 'not-active' : ''">
                <app-loader *ngIf="recompute_data_lock || recompute_loading || registration_loading"></app-loader>
                <div class="row vertical_center_flex">
                    <div class="col-md-8 vertical_center_flex">
                        <img src="assets/images/logo/leed.png" class="review_list_image">
                        <span class="cert_list_rs">{{selection_review_data.cert_rating_system}}</span>
                    </div>
                    <div class="col-md-4 cursor-pointer align-right" (click)="certGuideRedirect()">
                        <div class="recert_guidance fs15 line_height_24 pl5" *ngIf="selection_review_data.review_type == 'LEED Certification' && need_redirect_guidance"><i class="icon-external-link recert_guidance"></i> Recertification Guidance</div>
                        <div class="recert_guidance fs15 line_height_24 pl5" *ngIf="selection_review_data.review_type == 'Certification' && need_redirect_guidance"><i class="icon-external-link recert_guidance"></i> Certification Guidance</div>
                        <div class="recert_guidance fs15 line_height_24 pl5" *ngIf="selection_review_data.review_type == 'Pre-Certification' && need_redirect_guidance"><i class="icon-external-link recert_guidance"></i> Pre-Certification Guidance</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 cert_review_label pt20" *ngIf="registration_status != 'registered'">1. Select certification</div>
                    <div class="col-md-12 cert_review_label pt20" *ngIf="registration_status == 'registered'">1. Rating System</div>
                    <div class="col-md-12 pt30" *ngIf="selection_review_data.need_review_type_drp">
                        <select class="border_radius_zero cert_selection_container_field cursor-pointer" [(ngModel)]="selection_review_data.review_type" (change)="updateCertification()">
                            <option *ngFor="let review of selection_review_data.review_types">{{review}}</option>
                        </select>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>

                        <div class="fs13 mt5" >
                          Recertification requires reporting data across all five performance prerequisites as outlined in the <a href="https://www.usgbc.org/resources/leed-v41-om-beta-guide" target="_blank">LEED v4.1 O+M Guide</a>.  A minimum of 40 points are required for recertification.
                        </div>
                    </div>
                    <div class="col-md-12 pt30" *ngIf="!selection_review_data.need_review_type_drp">
                        <span *ngIf="selection_review_data.review_type == 'LEED Certification'" class="cert_selection_container_field border-bottom-gray">
                            <span *ngIf="appData.get('buildingData').project_type == 'building' || appData.get('buildingData').project_type == 'school'"> {{selection_review_data.cert_rating_system}}</span>
                            <span *ngIf="appData.get('buildingData').project_type != 'building' && appData.get('buildingData').project_type != 'school'">{{selection_review_data.review_type}}</span>
                        </span>
                        <span *ngIf="selection_review_data.review_type != 'LEED Certification'">{{selection_review_data.review_type}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 cert_review_label pt40">2. Select reporting period
                        <div class="display-inline">
                            <i class="fa fa-question-circle fontSize14 cursor-pointer" data-tooltip-placement="top" data-tooltip="The reporting period is 365 consecutive days. The time frame identified is used to calculate performance score and will be the same for all performance categories, LEED credits, and prerequisites. Ensure that the data and documentation provided in the LEED application aligns with the selected reporting period." style="position: absolute; top: 45px; "></i>
                        </div>
                    </div>
                    <div class="col-md-12 pt30 pr0">
                        <div class="col-md-8 border pt25 pb25">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="fs14 line_height_24 light_gray">
                                        <span>Select the end date for your reporting period</span>
                                        <span>
                                            <i class="fa fa-question-circle fontSize14 w18px cursor-pointer pl5" data-tooltip-placement="top" data-tooltip="The reporting period is 365 consecutive days. The time frame identified is used to calculate performance score and will be the same for all performance categories, LEED credits, and prerequisites. Ensure that the data and documentation provided in the LEED application aligns with the selected reporting period."></i>
                                        </span>
                                    </div>

                                    <div class="vertical_center_flex border_bottom_gray cursor-pointer" (click)="triggerEndDate()">
                                        <input type='text' class="form-control" id='end_review_date' [(ngModel)]="selection_review_data.review_end_date" readonly="readonly">
                                        <img class="cert_calendar" src="assets/images/calendar.png">
                                    </div>
                                    <div class="fs14 line_height_24 color_blue pt8 cursor-pointer" (click)="changePerformancePeriod(true)">Reset to Current Total Score</div>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-4">
                                    <div class="fs16 line_height_24">Reporting period Score</div>
                                    <div class="fs24 line_height_24 fw-500 flex pt10">
                                        <div *ngIf="!selection_review_data.selected_review_end_date">NA</div>
                                        <div *ngIf="selection_review_data.selected_review_end_date" class="value">{{selection_review_data.target_score}}</div>
                                        <div class="cert_unit">/100</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="fs14 line_height_24 pt8 col-md-12" *ngIf="selection_review_data.selected_review_end_date">{{selection_review_data.date_range}}</div>
                            </div>
                            <div class="row" *ngIf="selection_review_data.project_settings != '' && selection_review_data.selected_review_end_date">
                                <div class="fs14 line_height_24 pt20 col-md-12">{{selection_review_data.project_settings}}</div>
                            </div>
                            <div class="row">
                                <!-- Prerequisites starts -->
                                <div *ngIf="(selection_review_data.review_type == 'Certification' || selection_review_data.review_type  == 'Pre-Certification') && !global.isLEEDCertified(appData.get('buildingData').certification)">
                                    <div class="row pr10 mt30 ff-ss-sb">
                                        <h4 class="pl15">Prerequisites</h4>
                                    </div>

                                    <div class="row pr10">
                                        <table class="table table-hover ml10">
                                            <thead>
                                                <tr class="ff-ss-sb">
                                                    <th class="no-sort bg_none capitalize fw-500 line_height_24 fs14" width="55%">NAME</th>
                                                    <th class="bg_none w3p capitalize fw-500 line_height_24 fs14" width="30">DOCUMENT</th>
                                                    <th class="bg_none capitalize fw-500 line_height_24 fs14">STATUS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let action of selection_review_data.all_actions_filter_X" class="fs16 line_height_24">
                                                    <td class="vertical-align-middle text-left">
                                                        <img [src]="global.getCreditIcon(action)" class="height-24 mr5 w25px pull-left">
                                                        <span class="fs16 fw-400">{{action.CreditDescription}}
                                                        </span>
                                                    </td>
                                                    <td class="fw-bold vertical-align-middle">
                                                        <span class="fw-600" *ngIf="action.document_count!=0">{{action.document_count}}</span>
                                                        <img *ngIf="action.document_count!=0" src="assets/images/clip_icon.svg" class="width14">
                                                    </td>
                                                    <td class="fw-400 vertical-align-middle" [ngClass]="action.CreditStatus == 'Ready for Review' ? 'status_complete' : 'status_incomplete'">

                                                        <img *ngIf="action.CreditStatus == 'Ready for Review'" src="assets/images/done_green_icon.svg" class="w30px mt-n3px">

                                                        <img *ngIf="action.CreditStatus != 'Ready for Review'" src="assets/images/notdone_x_icon.svg" class="w30px mt-n3px">

                                                        <span class="fs16 green-color" *ngIf="action.CreditStatus == 'Ready for Review'">Ready for Review</span>

                                                        <span class="fs16 danger-color" *ngIf="action.CreditStatus != 'Ready for Review'">Missing</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- Prerequisites ends -->

                                <!-- Basepoints starts -->
                                <div *ngIf="(selection_review_data.review_type == 'Certification' || selection_review_data.review_type  == 'Pre-Certification') && (appData.get('buildingData').project_type=='city' || appData.get('buildingData').project_type=='community') && !global.isLEEDCertified(appData.get('buildingData').certification)">
                                    <div class="row pr10 mt20 ff-ss-sb">
                                        <div class="col-md-12">
                                            <h4 class="pl0">Base Points</h4>
                                            <p class="fs16 ff-ss-r mb0">Check which base points to submit for review by selecting from the list below.</p>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row pr10">
                                        <table class="table table-hover ml15">
                                            <thead>
                                                <tr class="ff-ss-sb">
                                                    <th class="no-sort bg_none capitalize fw-500 line_height_24 fs14" width="5%">Submit</th>
                                                    <th class="no-sort bg_none capitalize fw-500 line_height_24 fs14" width="46%">Category</th>
                                                    <th class="bg_none w3p capitalize fw-500 line_height_24 fs14" width="30%">Documentation</th>
                                                    <th class="bg_none capitalize fw-500 line_height_24 fs14">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="fs16 line_height_24">
                                                    <td class="vertical-align-middle text-left pl20">
                                                        <div class="checkbox checkbox-primary" *ngIf="base_status == 'Ready for Review'">
                                                            <input id="checkbox-base_score" type="checkbox" (change)='updateCheckedCredits("base_score")'>
                                                            <label for="checkbox-base_score"></label>
                                                        </div>
                                                    </td>
                                                    <td class="vertical-align-middle text-left">
                                                        <img src="assets/images/icons/city/plus_base.png" class="p2px height-24 mr5 w25px pull-left">
                                                        <span class="fs16 fw-400">Base Points
                                                        </span>
                                                    </td>
                                                    <td class="fw-bold vertical-align-middle">
                                                        <span class="fw-600" *ngIf="base_score_city_files.length>0">{{base_score_city_files.length}}</span>
                                                        <img *ngIf="base_score_city_files.length > 0" src="assets/images/clip_icon.svg" class="width14">
                                                    </td>
                                                    <td class="fw-400 vertical-align-middle" [ngClass]="base_status == 'Ready for Review' ? 'status_complete' : 'status_incomplete'">

                                                        <img *ngIf="base_status == 'Ready for Review'" src="assets/images/done_green_icon.svg" class="w30px mt-n3px">

                                                        <img *ngIf="base_status != 'Ready for Review'" src="assets/images/notdone_x_icon.svg" class="w30px mt-n3px">

                                                        <span class="fs16 green-color" *ngIf="base_status == 'Ready for Review'">Ready for Review</span>

                                                        <span class="fs16 danger-color" *ngIf="base_status != 'Ready for Review'">Missing</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="(selection_review_data.review_type == 'Certification' || selection_review_data.review_type  == 'Pre-Certification') && (appData.get('buildingData').project_type=='building' || appData.get('buildingData').project_type=='school' || appData.get('buildingData').project_type=='transit') && !global.isLEEDCertified(appData.get('buildingData').certification)">
                                    <div class="row pr10 mt20 ff-ss-sb">
                                        <div class="col-md-12">
                                            <h4 class="pl0">Base Points</h4>
                                            <p class="fs16 ff-ss-r mb0">Check which base points to submit for review by selecting from the list below.</p>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row pr10">
                                        <table class="table table-hover ml15">
                                            <thead>
                                                <tr class="ff-ss-sb">
                                                    <th class="no-sort bg_none capitalize fw-500 line_height_24 fs14" width="5%">Submit</th>
                                                    <th class="no-sort bg_none capitalize fw-500 line_height_24 fs14" width="46%">Category</th>
                                                    <th class="bg_none w3p capitalize fw-500 line_height_24 fs14" width="30%">Documentation</th>
                                                    <th class="bg_none capitalize fw-500 line_height_24 fs14">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let action of selection_review_data.all_actions_filter_only_base" class="fs16 line_height_24s">
                                                    <td class="vertical-align-middle text-left pl20">
                                                        <div class="checkbox checkbox-primary" *ngIf="action.CreditStatus == 'Ready for Review'">
                                                            <input id="checkbox-{{(action.CreditShortId).replace('.', '-')}}" type="checkbox" (change)='updateCheckedCredits(action.CreditShortId)'>
                                                            <label for="checkbox-{{(action.CreditShortId).replace('.', '-')}}"></label>
                                                        </div>
                                                    </td>
                                                    <td class="vertical-align-middle text-left">
                                                        <img [src]="global.getCreditIcon(action)" class="height-24 mr5 w25px pull-left">
                                                        <span class="fs16 fw-400">{{action.CreditDescription}}
                                                        </span>
                                                    </td>
                                                    <td class="fw-bold vertical-align-middle">
                                                        <span class="fw-600" *ngIf="action.document_count!=0">{{action.document_count}}</span>
                                                        <img *ngIf="action.document_count!=0" src="assets/images/clip_icon.svg" class="width14">
                                                    </td>
                                                    <td class="fw-400 vertical-align-middle" [ngClass]="action.CreditStatus == 'Ready for Review' ? 'status_complete' : 'status_incomplete'">

                                                        <img *ngIf="action.CreditStatus == 'Ready for Review'" src="assets/images/done_green_icon.svg" class="w30px mt-n3px">

                                                        <img *ngIf="action.CreditStatus != 'Ready for Review'" src="assets/images/notdone_x_icon.svg" class="w30px mt-n3px">

                                                        <span class="fs16 green-color" *ngIf="action.CreditStatus == 'Ready for Review'">Ready for Review</span>
                                                        <span class="fs16 danger-color" *ngIf="action.CreditStatus != 'Ready for Review'">Missing</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- Basepoints ends -->

                                <!-- DataInput starts -->
                                <div class="row mt20 ff-ss-sb">
                                    <div class="col-md-12">
                                        <h4 class="pl0">{{DI_heading_name}}</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <table class="table table-hover ml10" [ngClass]="selection_review_data.selected_review_end_date ? '': 'not-active'">
                                        <thead>
                                            <tr class="ff-ss-sb">
                                                <th [hidden]="!isBuildingRecert" class="no-sort bg_none capitalize fw-500 line_height_24 fs14" width="10%">Submit</th>
                                                <th class="no-sort bg_none capitalize fw-500 line_height_24 fs14 pl15" width="30%">Category</th>
                                                <th class="no-sort bg_none capitalize fw-500 line_height_24 fs14" width="22%">Score</th>
                                                <th class="no-sort bg_none capitalize fw-500 line_height_24 fs14" width="22%">Data completion</th>
                                                <th class="no-sort bg_none capitalize fw-500 line_height_24 fs14" width="15%">Documentation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let action of selection_review_data.all_actions_filter_only_pf" class="ff-ss-r">
                                                <td [hidden]="!isBuildingRecert" class="vertical-align-middle text-left pl20" [ngClass]="((selection_review_data.cert_rating_system == 'LEED v4 O+M: Existing Buildings recertification' || selection_review_data.cert_rating_system == 'LEED v4 O+M: Interiors recertification') && (action.CreditShortId == 'PF901' || action.CreditShortId == 'PF902')) || ((selection_review_data.cert_rating_system == 'LEED v4.1 O+M: Existing Buildings recertification' || selection_review_data.cert_rating_system == 'LEED v4.1 O+M: Interiors recertification')) ? 'cursor-not-allowed':''">
                                                    <div [ngClass]="((selection_review_data.cert_rating_system == 'LEED v4 O+M: Existing Buildings recertification' || selection_review_data.cert_rating_system == 'LEED v4 O+M: Interiors recertification') && (action.CreditShortId == 'PF901' || action.CreditShortId == 'PF902')) || ((selection_review_data.cert_rating_system == 'LEED v4.1 O+M: Existing Buildings recertification' || selection_review_data.cert_rating_system == 'LEED v4.1 O+M: Interiors recertification')) ? 'not-active':'cursor-pointer'">
                                                        <input class="cursor-pointer" id="checkbox-{{(action.CreditShortId).replace('.', '-')}}" type="checkbox" (change)='updateCheckedCredits(action.CreditShortId)' [checked]="selection_review_data.checked_credits.indexOf(action.CreditShortId) > -1">
                                                        <label for="checkbox-{{(action.CreditShortId).replace('.', '-')}}"></label>
                                                    </div>
                                                </td>
                                                <td class="vertical-align-middle text-left fs16 line_height_24 pb10 pt10">
                                                    <img [src]="global.getCreditIcon(action)" class="height-24 mr5 w25px pull-left">
                                                    <span class="fs16 fw-400">{{getCreditName(action)}}
                                                    </span>
                                                </td>

                                                <td *ngIf="!selection_review_data.selected_review_end_date" class="fs16 line_height_24 pb10 pt10">
                                                    <span *ngIf="action.CreditShortId.toLowerCase() != 'pf906'">
                                                        <span>NA</span>
                                                        <span class="cert_unit">/</span>
                                                        <span class="cert_unit">{{action.maxima}}</span>
                                                    </span>
                                                </td>
                                                <td *ngIf="!selection_review_data.selected_review_end_date" class="fs16 line_height_24 vertical-align-middle pb10 pt10">
                                                    <span class="fw-500 fs16 line_height_24 pr5" *ngIf="action.CreditShortId.toLowerCase() != 'pf906'">NA</span>
                                                </td>
                                                <td *ngIf="!selection_review_data.selected_review_end_date" class="fw-bold vertical-align-middle pb10 pt10">
                                                    <span class="fw-500 fs16 line_height_24 pr5">NA</span>
                                                </td>

                                                <td *ngIf="selection_review_data.selected_review_end_date" class="fs16 line_height_24 pb10 pt10">
                                                    <span *ngIf="action.CreditShortId.toLowerCase() != 'pf906'">
                                                        <span *ngIf="action.score">{{action.score}}</span>
                                                        <span *ngIf="!action.score">0</span>
                                                        <span class="cert_unit">/</span>
                                                        <span class="cert_unit">{{action.maxima}}</span>
                                                    </span>
                                                    <img *ngIf="action.score && action.CreditShortId.toLowerCase() != 'pf906'" src="assets/images/done_green_icon.svg" class="datacoverage_item w70px">
                                                    <img *ngIf="!action.score && action.CreditShortId.toLowerCase() != 'pf906'" src="assets/images/notdone_x_icon.svg" class="datacoverage_item w70px">
                                                </td>
                                                <td *ngIf="selection_review_data.selected_review_end_date" class="fs16 line_height_24 vertical-align-middle pb10 pt10">
                                                    <span class="fw-500 fs16 line_height_24 pr5" *ngIf="action.CreditShortId.toLowerCase() != 'pf906'">{{formatToInt(action.datacoverage.percentage)}} %</span>
                                                    <img *ngIf="action.datacoverage.coverage_flag == 'green' && action.CreditShortId.toLowerCase() != 'pf906'" src="assets/images/done_green_icon.svg" class="datacoverage_item w70px">
                                                    <img *ngIf="action.datacoverage.coverage_flag != 'green' && action.CreditShortId.toLowerCase() != 'pf906'" src="assets/images/notdone_x_icon.svg" class="datacoverage_item w70px">
                                                </td>
                                                <td *ngIf="selection_review_data.selected_review_end_date" class="fw-bold vertical-align-middle pb10 pt10">
                                                    <span class="fw-500 fs16 line_height_24 pr5" *ngIf="action.document_count!=0">{{action.datacoverage.documents}}</span>
                                                    <img *ngIf="action.document_count!=0" src="assets/images/clip_icon.svg" class="width14">
                                                    <img *ngIf="action.document_count" src="assets/images/done_green_icon.svg" class="datacoverage_item w70px">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- DataInput ends -->
                            </div>
                        </div>
                        <div class="col-md-4">
                            <iframe class="border" id="review_scores_chart" frameborder="0" width="100%" height="395px"></iframe>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 cert_review_label pt40">
                        <span>3. Select the primary point of contact for the review</span>
                        <span (click)="global.showHelpText('team_roles_help')" class="cursor-pointer vertical_align_middle pl5">
                            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="#68737D"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
                        </span>
                    </div>
                    <div class="col-md-12 pt10">
                        <select class="border_radius_zero cert_selection_container_field cursor-pointer" [(ngModel)]="selection_review_data.point_of_contact">
                            <option hidden disabled selected value></option>
                            <option *ngFor="let team_member of team_members" [value]="team_member.Useralias">
                                {{team_member.Firstname}} {{team_member.Lastname}} ({{team_member.Useralias}})
                            </option>
                        </select>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7 cert_review_label pt40">4. Additional information (optional)</div>
                    <div class="col-md-7 row pl15 pr10">
                        <table class="table table-hover">
                            <thead>
                                <tr class="ff-ss-sb">
                                    <th class="no-sort bg_none capitalize fw-500 line_height_24 fs14" width="46%">File name</th>
                                    <th class="bg_none w3p capitalize fw-500 line_height_24 fs14">Documentation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  *ngFor="let uploaded_file of config.uploaded_files" class="ff-ss-r">
                                    <td class="vertical-align-middle text-left">
                                        <a (click)="global.downloadFile(uploaded_file)"><span>{{global.getFileName(uploaded_file)}}</span></a>
                                    </td>
                                    <td class="fw-bold vertical-align-middle pl20">
                                        <span (click)="deleteFileAdditionalFile(uploaded_file, this)" class='icon-trash cursor-pointer'>
                                            <span *ngIf="showThisLoader" class='uploadLoaderSpinner spinner_review'></span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div [ngClass]="config.currentUpload ? 'not-active':''" type="extra_files" class="viewUpload">
                            <div (click)="global.openUploadDialog('laptop', 'additional_file', 'S3')" class="cursor-pointer cert_upload">
                                <span>
                                    Upload file
                                </span>
                            </div>
                            <input #elem (input)="global.fileNameChanged(elem)" class="hide uploadFile" type="file" multiple>
                        </div>
                        <div class="pt40">
                            <div  *ngFor="let uploaded_file of config.uploading" class="pl20">
                                <span class='uploadLoaderSpinner'></span>
                                <span>{{config.currentUploadName}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 pl15 pr10 pb40">
                        <p class="fs16 line_height_26 fw-500 ff-ss-r pb10">Anything else you would like to tell us?</p>
                        <textarea maxlength="5000" rows="8" class="w100p resize_none p20 fs16 line_height_24" [(ngModel)]="selection_review_data.extra_info_text" placeholder="Enter description"></textarea>
                        <div class="fs14 line_height_24 light_gray">Maximum 5000 characters</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <button class="btn btn-primary mr25" *ngIf="selection_review_data.only_review" [disabled]="!selection_review_data.point_of_contact || !selection_review_data.ready_for_review || !selection_review_data.selected_review_end_date" [ngClass]="selection_review_data.ready_for_review ? '' : 'opacity_0_5'" (click)="goToReviewPayment()">{{selection_review_data.only_review_button}}<img src="assets/images/arrow_right_white.svg" class="pl5"></button>
                        <button class="btn btn-primary mr25" *ngIf="!selection_review_data.only_review" [disabled]="!selection_review_data.point_of_contact || !selection_review_data.ready_for_review || !selection_review_data.selected_review_end_date" [ngClass]="selection_review_data.ready_for_review ? '' : 'opacity_0_5'" (click)="goToReviewPayment()">Continue<img src="assets/images/arrow_right_white.svg" class="pl5"></button>
                        <button class="btn btn-default" (click)="global.goToApp('selected_project', { 'leed_id': appData.get('leed_id'), 'app_name': 'certifications'})">Cancel</button>
                    </div>
                    <p *ngIf="!selection_review_data.selected_review_end_date && selection_review_data.ready_for_review && !selection_review_data.max_limit_block" class="col-md-12 mt10 danger-color">
                        Please select the end date for your reporting period
                    </p>
                    <p *ngIf="!selection_review_data.point_of_contact && selection_review_data.ready_for_review && !selection_review_data.max_limit_block" [ngClass]="selection_review_data.selected_review_end_date ? 'mt10' : 'mtn10'" class="col-md-12 danger-color">
                        Please select the primary point of contact for the review
                    </p>
                    <div [hidden]="selection_review_data.ready_for_review || selection_review_data.max_limit_block" class="col-md-12 pr0 danger-color mt10">
                        <span *ngIf="selection_review_data.manage_billing_flag">
                            Please complete your <a (click)="goToManageBilling()">review payment</a> in order to proceed
                        </span>
                        <span *ngIf="!selection_review_data.manage_billing_flag">
                            <span *ngIf="selection_review_data.ready_for_review_txt == 'Registration payment is still under process'">Please complete your <a (click)="goToManageBilling()">registration payment</a> in order to proceed</span>
                            <span *ngIf="selection_review_data.ready_for_review_txt != 'Registration payment is still under process'">{{selection_review_data.ready_for_review_txt}}</span>
                        </span>
                    </div>
                    <p *ngIf="selection_review_data.max_limit_block" class="col-md-12 mt10 danger-color">
                        <a href="mailto:contact@arcskoru.com" target="_top">Contact us </a>
                        to submit review for this project
                    </p>
                </div>
            </div>
            <div class="credit_doc_notification modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false">
                <div class="modal-dialog modal-sm" style="width: 460px;">
                    <div class="modal-content p40 modal_radius">
                        <p class="align-center">We noticed you provided documentation or/and marked credit as Ready for Review for following credits, did you mean to include it in your submission? If so, make sure you mark them as Ready for Review and select the submit checkbox before continuing.</p>
                            <ul>
                                <li *ngFor="let x of alert_of">{{x}}</li>
                            </ul>
                        <div class="mt30 align-center">
                            <button class="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button class="btn btn-primary ml10" data-dismiss="modal" (click)="goToReviewPayment_modal()">Continue with submission</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block_review_cert modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog modal-md">
                    <div class="modal-content p40 modal_radius fs16 line_height_24">
                        <p *ngIf="!selection_review_data.max_limit_block" class="col-md-12 pr0 danger-color mt10">
                            <span *ngIf="selection_review_data.manage_billing_flag">
                                Please complete your <a (click)="goToManageBilling()">review payment</a> in order to proceed
                            </span>
                            <span *ngIf="!selection_review_data.manage_billing_flag">
                                <span *ngIf="selection_review_data.ready_for_review_txt == 'Registration payment is still under process'">Please complete your <a (click)="goToManageBilling()">registration payment</a> in order to proceed</span>
                                <span *ngIf="selection_review_data.ready_for_review_txt != 'Registration payment is still under process'">{{selection_review_data.ready_for_review_txt}}</span>
                            </span>
                        </p>
                        <p *ngIf="selection_review_data.max_limit_block" class="align-center col-md-12 pl0 pr0 mt10">
                            <a href="mailto:contact@arcskoru.com" target="_top">Contact us </a>
                            to submit review for this project
                        </p>
                        <div class="pt50 align-center">
                            <button class="btn btn-default" data-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="review_not_allowed_modal modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="p2535">
                            <div class="left-right-align mb20">
                                <h4 class="mb10 mt10 fw-600">Review not allowed</h4>
                            </div>
                            <div class="left-right-align fs16">
                                <div *ngIf="!selection_review_data.max_limit_block" class="danger-color">
                                    <div *ngIf="selection_review_data.manage_billing_flag">
                                        <div *ngIf="selection_review_data.review_area_changed">The Gross Floor Area increased after the LEED Certification invoice has been created. A support case has been created with GBCI who will reach out to confirm the Gross Floor Area and issue an invoice if necessary.</div>
                                        <div *ngIf="!selection_review_data.review_area_changed">Please complete your <a (click)="goToManageBilling()">review payment</a> in order to proceed</div>
                                    </div>
                                    <div *ngIf="!selection_review_data.manage_billing_flag">
                                        <div *ngIf="selection_review_data.ready_for_review_txt == 'Registration payment is still under process'">Please complete your <a (click)="goToManageBilling()">registration payment</a> in order to proceed</div>
                                        <div *ngIf="selection_review_data.ready_for_review_txt != 'Registration payment is still under process'">{{selection_review_data.ready_for_review_txt}}</div>
                                    </div>
                                </div>
                                <div *ngIf="selection_review_data.max_limit_block" class="align-center">
                                    <a href="mailto:contact@arcskoru.com" target="_top">Contact us </a>
                                    to submit review for this project
                                </div>
                            </div>
                            <div class="mt15 fs16 danger-color" *ngIf="appData.get('buildingData').test_project">
                                Review submission for test projects not allowed.
                            </div>
                            <div class="mt30">
                                <div class="align-left">
                                    <button (click)="global.goToApp('selected_project', { 'leed_id': appData.get('leed_id'), 'app_name': 'certifications'})" data-dismiss="modal" type="button" class="btn btn-default btn-md">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="registration_not_found modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="p2535">
                            <div class="left-right-align mb20">
                                <h4 class="mb10 mt10 fw-600">Review not allowed</h4>
                            </div>
                            <div class="mt15 fs16 danger-color">
                                Registration for recertification not found.
                            </div>
                            <div class="mt30">
                                <div class="align-left">
                                    <button (click)="global.goToApp('selected_project', { 'leed_id': appData.get('leed_id'), 'app_name': 'certifications'})" data-dismiss="modal" type="button" class="btn btn-default btn-md">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="recert_categories_confirmation" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div class="modal-dialog modal-md">
                    <div class="modal-content p30 modal_radius pt50">
                        <p class="fs16 align-center">Are you sure you want to submit less than 5 categories?</p>
                        <div class="mt30 align-center">
                            <button class="btn btn-primary fs16 h40" data-dismiss="modal" aria-label="Close" (click)="checkLEEDACPprerequisite()" [ngClass]="config.access == 'R' ? 'not-active' : ''">Continue</button>
                            <button class="btn btn-default fs16 h40 ml15" data-dismiss="modal" aria-label="Close">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="leed_acp_warning" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div class="modal-dialog modal-md">
                    <div class="modal-content p30 modal_radius pt50">
                        <p class="fs16 align-center">{{acp_warning_message}}</p>
                        <div class="mt30 align-center">
                            <button class="btn btn-primary fs16 h40" data-dismiss="modal" aria-label="Close" (click)="goToPaymentOrSubmit()" [ngClass]="config.access == 'R' ? 'not-active' : ''">Continue</button>
                            <button class="btn btn-default fs16 h40 ml15" data-dismiss="modal" aria-label="Close">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
