import { Injectable } from '@angular/core';
import { AppData } from './appdata.service';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class LanguageService
{
	languages: any = [];
	language: any = {};
	texts: any = {
		"english":{
			"survey": "Survey",
			"transport":"Transport",
			"typical_day": 'How do you typically get to this building? If your travel methods vary by day of the week (including telecommuting), enter a route for each day. If you are a Visitor, enter one route for how you traveled to this building.',
			"typical_day_v1": 'On a typical day, how do you get to and from this building? Enter results for "one day, one way"',
			"one_day": "If your transportation methods vary by day of the week or travel direction, enter multiple routes",
			"route": "Route",
			"select_travel": "Select Travel Method",
			"mandatory_field_error": "This is a required question",
			"blank_satisfaction_error": "Required question. Use the slider to make a selection.",
			"walk_bike_telecommute": "Walk, Bike, Telecommute",
			"walk": "Walk",
			"bike": "Bicycle or electric scooters",
			"telecommute": "Telecommute",
			"bus": "Bus",
			"light_rail": "Light rail (trolley, tram, streetcar)",
			"heavy_rail": "Rapid transit (subway, metro)",
			"motorcycle": "Motorcycle or scooter",
			"car": "Car solo",
			"car23": "Carpool 2-3 people/Vanpool",
			"cars4": "Car: Alternate fuel",
			"kilometers": "Kilometer",
			"miles": "Miles",
			"add_route": "Add Another Route",
			"remove_route": "Remove Route",
			"satisfaction": "Satisfaction",
			"how_satisfied": "1. Use the slider to indicate how satisfied you are with the environment in this building",
			"how_satisfied_v1": "Use the slider to indicate how satisfied you are with the environment in this building",
			"extremely_unsatisfied":"Extremely Unsatisfied",
			"extremely_satisfied": "Extremely Satisfied",
			"name": "Name (Optional)",
			"submit": "Submit",
			"compliment_message": "2. We're glad to hear that. Please select the options below that significantly enhance your satisfaction:",
			"compliment_message_v1": "We're glad to hear that. Please select the options below that significantly enhance your satisfaction:",
			"sorry_message": "2. We're sorry to hear that. Please select the options below that significantly reduce your satisfaction:",
			"sorry_message_v1": "We're sorry to hear that. Please select the options below that significantly reduce your satisfaction:",
			"dirty": "Dirty",
			"drafty": "Drafty",
			"bright": "Bright",
			"views": "Views to Outdoors",
			"views_satisfied": "Views to Outdoors",
			"sound": "Sound",
			"sound_satisfied": "Sound",
			"humid": "Humid",
			"smelly": "Smelly",
			"stuffy": "Stuffy",
			"loud": "Acoustics",
			"loud_v2": "Loud",
			"hot": "Hot",
			"cold": "Cold",
			"dark": "Dark",
			"glare": "Glare",
			"privacy": "Privacy",
			"privacy_satisfied": "Privacy",
			"thermal_comfort": "Thermal Comfort",
			"air_quality": "Air Quality",
			"cleanliness": "Cleanliness",
			"light": "Light",
			"daylight": "Daylight",
			"location": "Location",
			"comments": "Comments (Optional)",
			"other_complaints": "Other Complaints",
			"other_location": "Location - eg. Lobby, 2nd Floor East, 50th Floor - Suite 500",
			"submit_text": "Submitting...",
			"ok": "Ok",
			"error_sending_survey": "Please fill in the transportation data to be able to submit the survey",
			"thankyou_message": "Thank you for taking the time.",
			"reading_error_survey": "",
			"acceptnclose": "ACCEPT AND CLOSE",
			"cookies_msg_one": "This website collects and uses cookies to ensure you get the best user experience.",
			"cookies_msg_two": 'Please click on "Accept and Close" button to affirm your consent and continue to use our website. For more information, please view our',
			"cookies_msg_three": 'Cookies Statement',
			"save": "SAVE",
			"cancel": "CANCEL",
			"travel_method": "Travel Method",
			"distance_in": "Distance In:",
			"clear": "Clear",
			"modal_message": "Include any miles you travel to get to this building.",
			"modal_message_si": "Include any kilometers you travel to get to this building.",
			"neutral": "Neither satisfied nor unsatisfied",
			"satisfaction_none": "Neither satisfied nor unsatisfied",
			"satisfied": "Satisfied",
			"very_satisfied": "Very Satisfied",
			"unsatisfied": "Unsatisfied",
			"very_unsatisfied": "Very Unsatisfied",
			"about_arc": "About Arc",
			"arc_policy": "Privacy Policy",
			"thank_you_message":"Thank you for taking our survey! Your responses:",
			"transportation":"Transportation",
			"enhance_message": "Options that enhance your satisfaction",
			"reduce_message": "Options that reduce your satisfaction",
			"lobby_page_thankyou": "Thank you for taking our survey!",
			"start_survey": "Start a New Survey",
			"high_mileage": "High Mileage",
			"slide_to_select": "Slide to your selection",
			"ignore": "Ignore",
			"occupant_type_question": "Which type of occupant are you?",
			"occupant_type": "Select Occupant Type",
			"regular": "Regular Occupant",
			"visitor": "Visitor",
			"submit_error": "Please answer all the questions",
			"energy": "ENERGY",
			"water": "WATER",
			"waste": "WASTE",
			"transportation_label": "Transportation",
			"human_experience": "Human Experience",
			"silver": "SILVER",
			"gold": "GOLD",
			"platinum": "PLATINUM",
			"certified": "CERTIFIED",
			"last_cert": "Last certification date",
			"level_cert": "Certification Level",
			"next": "Next",
			"skip": "Skip",
			"previous": "Previous",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "Submit one or both of the following",
			"Number of days in a week, you use this route":"Number of days in a week, you use this route",
			"day": "day",
			"visitorRouteError": "Visitors can only add 1 route."
		},
		"french":{
			"survey": "Enquête",
			"transport":"Transport",
			"typical_day": "Comment arrivez-vous généralement à ce bâtiment ? Si vos modes de déplacement varient selon les jours de la semaine (y compris le télétravail), saisissez un itinéraire pour chaque jour. Si vous êtes un visiteur, entrez un itinéraire indiquant comment vous vous êtes rendu à ce bâtiment.",
			"typical_day_v1": "Quel est votre mode de transport principal pour vos déplacements domicile-travail ?",
			"one_day": "Le mode de transport dans lequel vous passez le plus de temps au cours d'un aller simple",
			"route": "Trajet",
			"select_travel": "Sélectionnez le mode de transport",
			"mandatory_field_error": "Réponse Obligatoire",
			"blank_satisfaction_error": "Réponse obligatoire. Utilisez le curseur pour faire la sélection.",
			"walk_bike_telecommute": "À Pied, À Vélo, Télétravail",
			"walk": "À Pied",
			"bike": "Vélo ou trottinette électrique",
			"telecommute": "Télétravail",
			"bus": "En Autobus",
			"light_rail": "En Tramway",
			"heavy_rail": "En Train, TER, RER",
			"motorcycle": "En Deux-roues motorisés",
			"car": "En Voiture",
			"car23": "Covoiturage 2-3 personnes/Vanpool",
			"cars4": "Véhicule Électrique ou Hybride",
			"kilometers": "kilomètre",
			"miles": "Miles",
			"add_route": "Ajouter un autre trajet",
			"remove_route": "Supprimer l'itinéraire",
			"satisfaction": "Enquête Satisfaction",
			"how_satisfied": "1. Êtes-vous satisfait de la qualité de l'environnement dans ce bâtiment?",
			"how_satisfied_v1": "Êtes-vous satisfait de la qualité de l'environnement dans ce bâtiment?",
			"extremely_unsatisfied": "Extrêmement insatisfait",
			"extremely_satisfied": "Extrêmement satisfait",
			"name": "Nom (facultatif)",
			"submit": "Soumettre",
			"compliment_message": "2. Nous sommes heureux de l'apprendre. Veuillez sélectionner les critères ci-dessous qui décrivent le mieux votre satisfaction:",
			"compliment_message_v1": "Nous sommes heureux de l'apprendre. Veuillez sélectionner les critères ci-dessous qui décrivent le mieux votre satisfaction:",
			"sorry_message": "2. Nous sommes désolés de l'apprendre. Quels sont les critères qui décrivent votre insatisfaction?",
			"sorry_message_v1": "Nous sommes désolés de l'apprendre. Quels sont les critères qui décrivent votre insatisfaction?",
			"dirty": "Manque de propreté",
			"smelly": "Mal odorant",
			"stuffy": "Manque d'aération",
			"loud": "Acoustique",
			"loud_v2": "Bruyant",
			"hot": "Trop chaud",
			"cold": "Trop froid",
			"dark": "Trop sombre",
			"glare": "Trop éblouissant",
			"privacy": "manque d'intimité",
			"privacy_satisfied": "Privé",
			"drafty": "Venteux, courants d'airs",
			"bright": "Trop lumineux",
			"views": "Manque de Vue sur l'extérieur",
			"views_satisfied": "Vues sur l'extérieur",
			"sound": "Bruyant",
			"sound_satisfied": "Calme",
			"humid": "Humide",
			"thermal_comfort": "Confort Thermique",
			"air_quality": "Qualité de l'air",
			"cleanliness": "Propreté",
			"light": "Éclairement",
			"daylight": "Lumière du jour",
			"location": "Emplacement",
			"other_complaints": "Autres insatisfactions",
			"other_location": "Votre emplacement ou emplacement concerné",
			"comments": "Autres commentaires (optionnel)",
			"submit_text": "Soumettre",
			"ok": "Ok",
			"error_sending_survey": "Veuillez répondre à l'enquête transport pour terminer l'enquête",
			"thankyou_message": "Merci d'avoir pris le temps de compléter ce questionnaire",
			"reading_error_survey": "",
			"acceptnclose": "ACCEPTER ET FERMER",
			"cookies_msg_one": "Ce site recueille et utilise des cookies pour vous offrir la meilleure expérience utilisateur.",
			"cookies_msg_two": "Veuillez cliquer sur le bouton ''Accepter et fermer'' pour confirmer votre consentement et continuer à utiliser notre site Web. Pour plus d'informations, veuillez consulter notre",
			"cookies_msg_three": "Déclaration relative à l'utilisation des cookies",
			"save": "ENREGISTRER",
			"cancel": "ANNULER",
			"travel_method": "Mode de transport",
			"distance_in": "Distance en:",
			"clear": "Effacer",
			"modal_message": "Quel est le nombre de kilomètres parcourus",
			"modal_message_si": "Quel est le nombre de kilomètres parcourus",
			"neutral": "Ni satisfait ni insatisfait",
			"satisfaction_none": "Ni satisfait ni insatisfait",
			"satisfied": "Satisfait",
			"very_satisfied": "Très Satisfait",
			"unsatisfied": "Insatisfait",
			"very_unsatisfied": "Très Insatisfait",
			"about_arc": "À propos de Arc",
			"arc_policy": "Politique de confidentialité",
			"thank_you_message":"Nous vous remercions pour votre participation à cette enquête !",
			"transportation":"Transport",
			"enhance_message": "Critères qui améliorent votre satisfaction",
			"reduce_message": "Critères qui altèrent votre satisfaction",
			"lobby_page_thankyou": "Nous vous remercions pour votre participation à cette enquête !",
			"start_survey": "Commencer",
			"high_mileage": "Kilométrage élevé",
			"slide_to_select": "Faites glisser le curseur pour sélectionner",
			"ignore": "Ignorer",
			"occupant_type_question": "Êtes-vous résident·e ou en visite ?",
			"occupant_type": "Sélectionnez",
			"regular": "Résident·e",
			"visitor": "En visite",
			"submit_error": "Veuillez répondre à toutes les questions",
			"energy": "Énergie",
			"water": "Eau",
			"waste": "Déchets",
			"transportation_label": "Transport",
			"human_experience": "Expérience humaine",
			"silver": "Argent",
			"gold": "Or",
			"platinum": "Platine",
			"certified": "Certifié",
			"last_cert": "Dernière date de certification",
			"level_cert": "Niveau de certification",
			"next": "Prochaine",
			"skip": "Sauter",
			"previous": "Précédent",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "Soumettez un ou les deux éléments suivants",
			"Number of days in a week, you use this route": "Nombre de jours dans une semaine pendant lesquels vous utilisez cet itinéraire",
			"day": "jour",
			"visitorRouteError": "Les visiteurs ne peuvent ajouter qu’un seul itinéraire."
		},
		"portuguese":{
			"survey": "Pesquisa",
			"transport":"Transporte",
			"typical_day": "Como você normalmente chega a este prédio? Se os seus métodos de viagem variarem de acordo com o dia da semana (incluindo teletrabalho), insira uma rota para cada dia. Se você for um Visitante, insira uma rota de como você viajou até este edifício.",
			"typical_day_v1": "Em um dia típico, como você chega e sai deste prédio? Insira os resultados para um dia, um caminho",
			"one_day": "Se os seus métodos de transporte variam de acordo com o dia da semana ou a direção da viagem, insira várias rotas",
			"route": "Rota",
			"select_travel": "Selecione o método de viagem",
			"mandatory_field_error": "Esta é uma pergunta obrigatória",
			"blank_satisfaction_error": "Pergunta obrigatória. Use o controle deslizante para fazer uma seleção.",
			"walk_bike_telecommute": "Caminhada, Bicicleta, Trabalho remoto",
			"walk": "Andar",
			"bike": "Bicicleta ou scooters elétricos",
			"telecommute": "Telecomutar",
			"bus": "Ônibus",
			"light_rail": "Bonde VLT",
			"heavy_rail": "Trem Metrô",
			"motorcycle": "Motocicleta",
			"car": "Carro:Sozinho",
			"car23": "Carpool 2-3 pessoas/Vanpool",
			"cars4": "Carro: Combustível alternativo",
			"kilometers": "Quilômetro",
			"miles": "Milha",
			"add_route": "Adicione a viagem de volta",
			"remove_route": "Remover rota",
			"satisfaction": "Satisfação",
			"how_satisfied": "1. Use o controle deslizante para indicar o quanto você está satisfeito com o ambiente nesse prédio",
			"how_satisfied_v1": "Use o controle deslizante para indicar o quanto você está satisfeito com o ambiente nesse prédio",
			"extremely_unsatisfied": "Extremamente insatisfeito",
			"extremely_satisfied": "Extremamente satisfeito",
			"name": "Nome (opcional)",
			"submit": "Submeter",
			"compliment_message": "2. Estamos felizes em ouvir isso. Por favor, selecione as opções abaixo que melhoram significativamente sua satisfação:",
			"compliment_message_v1": "Estamos felizes em ouvir isso. Por favor, selecione as opções abaixo que melhoram significativamente sua satisfação:",
			"sorry_message": "2. Nós sentimos por isso. Por favor, me diga quais das opções abaixo reduziram significativamente a sua satisfação.",
			"sorry_message_v1": "Nós sentimos por isso. Por favor, me diga quais das opções abaixo reduziram significativamente a sua satisfação.",
			"dirty": "Sujeira",
			"smelly": "Mal Cheiro",
			"stuffy": "Abafado",
			"loud": "Acústica",
			"loud_v2": "Alto",
			"hot": "Calor",
			"cold": "Frio",
			"dark": "Escuro",
			"glare": "Ofuscamento",
			"privacy": "Privacidade",
			"privacy_satisfied": "Privacidade",
			"drafty": "Esboçado",
			"bright": "Brilhante",
			"views": "Vistas para o exterior",
			"views_satisfied": "Vistas para o exterior",
			"sound": "Som",
			"sound_satisfied": "Som",
			"humid": "Úmido",
			"thermal_comfort": "Conforto térmico",
			"air_quality": "Qualidade do ar",
			"cleanliness": "Limpeza",
			"light": "Luz",
			"daylight": "Luz do dia",
			"location": "Location",
			"other_complaints": "Outras queixas",
			"other_location": "Localização: Ex. Recepção",
			"comments": "Comentários (opcional)",
			"submit_text": "Submeter...",
			"ok": "Está bem",
			"error_sending_survey": "Por favor, preencha os dados de transporte para poder enviar a pesquisa",
			"thankyou_message": "Obrigado pelo tempo disponibilizado.",
			"reading_error_survey": "",
			"acceptnclose": "ACEITAR E FECHAR",
			"cookies_msg_one": "Este site coleta e usa cookies para garantir que você tenha a melhor experiência de usuário.",
			"cookies_msg_two": 'Clique no botão "Aceitar e Fechar" para confirmar seu consentimento e continuar usando nosso site. Para mais informações, consulte nossa',
			"cookies_msg_three": 'Declaração de Cookies',
			"save": "SALVE",
			"cancel": "CANCELAR",
			"travel_method": "Método de viagem",
			"distance_in": "Distância em:",
			"clear": "Claro",
			"modal_message": "Inclua todas as milhas que você viajar para chegar a este edifício.",
			"modal_message_si": "Inclua todas as quilometros que você viajar para chegar a este edifício.",
			"neutral": "Nem satisfeito nem insatisfeito",
			"satisfaction_none": "Nem satisfeito nem insatisfeito",
			"satisfied": "Satisfeito",
			"very_satisfied": "Muito Satisfeito",
			"unsatisfied": "Insatisfeito",
			"very_unsatisfied": "Muito Insatisfeito",
			"about_arc": "Sobre o Arc",
			"arc_policy": "Política de Privacidade",
			"thank_you_message":"Obrigado por fazer nossa pesquisa! Suas respostas:",
			"transportation":"Transporte",
			"enhance_message": "Opções que aumentam sua satisfação",
			"reduce_message": "Opções que reduzem sua satisfação",
			"lobby_page_thankyou": "Obrigado por fazer nossa pesquisa!",
			"start_survey": "Iniciar uma Nova Pesquisa",
			"high_mileage": "Alta quilometragem",
			"slide_to_select": "Deslize para sua seleção",
			"ignore": "Ignorar",
			"occupant_type_question": "Que tipo de ocupante é você?",
			"occupant_type": "Selecione o tipo de ocupante",
			"regular": "Ocupante regular",
			"visitor": "Visitante",
			"submit_error": "Por favor, responda a todas as perguntas",
			"next": "Próxima",
			"skip": "Pular",
			"previous": "Anterior",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "Envie um ou ambos dos seguintes",
			"Number of days in a week, you use this route": "Número de dias em uma semana que você usa esta rota",
			"day": "dia",
			"visitorRouteError": "Os visitantes só podem adicionar 1 rota."
		},
		"spanish":{
			"survey": "Encuesta",
			"transport":"Transporte",
			"typical_day": "¿Cómo sueles llegar a este edificio? Si sus métodos de viaje varían según el día de la semana (incluido el teletrabajo), ingrese una ruta para cada día. Si es visitante, ingrese una ruta de cómo viajó a este edificio.",
			"typical_day_v1": "En un día típico ¿cómo llega y se va de este edificio? Introduzca los datos para un día, una ruta",
			"one_day": "Si sus métodos de transporte varían según el día de la semana o la dirección de viaje, ingrese múltiples rutas",
			"route": "Ruta",
			"select_travel": "Seleccione el método de viaje",
			"mandatory_field_error": "Esta pregunta es obligatoria",
			"blank_satisfaction_error": "Pregunta requerida. Usa el control deslizante para hacer una selección.",
			"walk_bike_telecommute": "Caminando, bicicleta, telecomunicación",
			"walk": "Caminar",
			"bike": "Bicicleta o patinetes eléctricos",
			"telecommute": "Telecommutar",
			"bus": "Autobús",
			"light_rail": "tranvía o tren ligero",
			"heavy_rail": "Tren",
			"motorcycle": "Motocicleta o Ciclomotor",
			"car": "Automóvil solo",
			"car23": "Compartir coche 2-3 personas/Vanpool",
			"cars4": "Automóvil de combustible alternativo",
			"kilometers": "Kilómetro",
			"miles": "Milla",
			"add_route": "Agregar otra ruta",
			"remove_route": "Quitar ruta",
			"satisfaction": "Satisfacción",
			"how_satisfied": "1. Deslice el botón azul para indicar qué tan satisfecho está con las condiciones del espacio en que trabaja",
			"how_satisfied_v1": "Deslice el botón azul para indicar qué tan satisfecho está con las condiciones del espacio en que trabaja",
			"extremely_unsatisfied":"Extremadamente insatisfecho",
			"extremely_satisfied": "Extremadamente satisfecho",
			"name": "Nombre (opcional)",
			"submit": "Enviar",
			"compliment_message": "2. Estamos contentos de escuchar eso. Seleccione las opciones a continuación que mejoran significativamente su satisfacción:",
			"compliment_message_v1": "Estamos contentos de escuchar eso. Seleccione las opciones a continuación que mejoran significativamente su satisfacción:",
			"sorry_message": "2. Lamentamos escuchar esto. Por favor, seleccione las opciones que reducen significativamente su satisfacción:",
			"sorry_message_v1": "Lamentamos escuchar esto. Por favor, seleccione las opciones que reducen significativamente su satisfacción:",
			"dirty": "Sucio",
			"smelly": "Maloliente",
			"stuffy": "mal ventilado",
			"loud": "Acústica",
			"loud_v2": "Ruidoso",
			"hot": "Caluroso",
			"cold": "Frío",
			"dark": "Oscuro",
			"glare": "Deslumbramiento",
			"privacy": "Privacidad",
			"privacy_satisfied": "Privacidad",
			"drafty": "Demasiadas corrientes de aire",
			"bright": "Exceso de luz",
			"views": "Sin vistas al exterior",
			"views_satisfied": "Vistas al exterior",
			"sound": "Ruidoso",
			"sound_satisfied": "Acústica",
			"humid": "Húmedo",
			"thermal_comfort": "Confort térmico",
			"air_quality": "Calidad del aire",
			"cleanliness": "Limpieza",
			"light": "Iluminado",
			"daylight": "Acceso a luz de día",
			"location": "Ubicación",
			"other_complaints": "Otras Quejas",
			"other_location": "Ubicación (ejemplo: vestíbulo)",
			"comments": "Comentarios (opcional)",
			"submit_text": "Sumisión...",
			"ok": "Bueno",
			"error_sending_survey": "Complete los datos de transporte para poder enviar la encuesta",
			"acceptnclose": "ACEPTAR Y CERRAR",
			"cookies_msg_one": "Diese Website sammelt und verwendet Cookies, um sicherzustellen, dass Sie die beste Nutzererfahrung erhalten.",
			"cookies_msg_two": 'Haga clic en el botón "Aceptar y cerrar" para confirmar su consentimiento y continuar utilizando nuestro sitio web. Para obtener más información, consulte nuestra',
			"cookies_msg_three": 'Declaración de Cookies',
			"save": "Guardar",
			"cancel": "CANCELAR",
			"travel_method": "Método de viaje",
			"distance_in": "Distancia En:",
			"clear": "Limpiar",
			"modal_message": "Incluya cualquier millas que viaje para llegar a este edificio.",
			"modal_message_si": "Indique cuántos kilómetros recorre para llegar a este edificio.",
			"neutral": "Tampoco satisfecho ni insatisfecho",
			"satisfaction_none": "Ni satisfecho ni insatisfecho",
			"satisfied": "Satisfecho",
			"very_satisfied": "Muy Satisfecho",
			"unsatisfied": "Insatisfecho",
			"very_unsatisfied": "Muy Insatisfecho",
			"about_arc": "Acerca de Arc",
			"arc_policy": "Política de privacidad",
			"thank_you_message":"Gracias por tomar nuestra encuesta! Sus respuestas:",
			"transportation":"Transporte",
			"enhance_message": "Optionen, die Ihre Zufriedenheit steigern",
			"reduce_message": "Opciones que reducen su satisfacción",
			"lobby_page_thankyou": "Gracias por tomar nuestra encuesta!",
			"start_survey": "Comience una Nueva Encuesta",
			"high_mileage": "Alto kilometraje",
			"slide_to_select": "Deslice a botón azul",
			"ignore": "Ignorar",
			"occupant_type_question": "¿Qué tipo de ocupante eres?",
			"occupant_type": "Seleccione el tipo de ocupante",
			"regular": "Ocupante regular",
			"visitor": "Visitante",
			"submit_error": "Por favor responda todas las preguntas",
			"next": "Próxima",
			"skip": "Saltar",
			"previous": "Previo",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "Envíe uno o ambos de los siguientes",
			"Number of days in a week, you use this route": "Número de días de la semana que utilizas esta ruta",
			"day": "día",
			"visitorRouteError": "Los/Las visitantes solo pueden agregar 1 ruta."
		},
		"chinese":{
			"survey": "调查/調查",
			"transport":"交通",
			"typical_day": '您通常如何到达这座大楼？ 如果您的出行方式因一周中的不同日期而异（包括远程办公），请输入每天的路线。 如果您是访客，请输入您前往该建筑的一条路线。',
			"typical_day_v1": '在一般情况下，您是采用哪种交通方式进出这幢建筑的？请输入通勤方式及距离“一天内，单程“',
			"one_day": "如果您的运输方式因星期几或旅行方向而异，请输入多条路线",
			"route": "路程",
			"select_travel": "选择通勤方式",
			"mandatory_field_error": "這是一個必需的問題。",
			"blank_satisfaction_error": "要求的問題。使用滑塊進行選擇。",
			"walk_bike_telecommute": "步行, 自行车/腳踏車, 远程工作",
			"walk": "步行",
			"bike": "自行車或電動滑板車",
			"telecommute": "远程工作",
			"bus": "公共汽车",
			"light_rail": "轻轨（电车，有轨电车)",
			"heavy_rail": "快速交通（地铁，捷運)",
			"motorcycle": "摩托车或踏板车",
			"car": "独自开车",
			"car23": "拼车2-3人/拼车",
			"cars4": "驾驶可替代燃料车",
			"kilometers": "千米",
			"miles": "英里",
			"add_route": "添加另一段行程",
			"remove_route": "删除路线",
			"satisfaction": "满意度",
			"how_satisfied": "1. 滑动滑块来对该建筑的室内环境舒适度进行评价",
			"how_satisfied_v1": "滑动滑块来对该建筑的室内环境舒适度进行评价",
			"extremely_unsatisfied":"非常不满意",
			"extremely_satisfied": "非常满意",
			"name": "姓名（选填)",
			"submit": "提交",
			"compliment_message": "2. 很高兴听到您的评价。请在下列选项中选出您最满意的方面：",
			"compliment_message_v1": "很高兴听到您的评价。请在下列选项中选出您最满意的方面：",
			"sorry_message": "2. 我们对您的评价感到遗憾。请在下列选项中选出严重影响您满意度的方面:",
			"sorry_message_v1": "我们对您的评价感到遗憾。请在下列选项中选出严重影响您满意度的方面:",
			"dirty": "脏乱",
			"smelly": "异味",
			"stuffy": "闷热",
			"loud": "音响效果差",
			"loud_v2": "音响效果差",
			"hot": "过热",
			"cold": "过冷",
			"dark": "光线太暗",
			"glare": "眩光",
			"privacy": "私密性不足",
			"privacy_satisfied": "私密性好",
			"drafty": "漏风",
			"bright": "光线太强",
			"views": "户外视野不足",
			"views_satisfied": "户外视野好",
			"sound": "噪音",
			"sound_satisfied": "声环境良好",
			"humid": "过于潮湿",
			"thermal_comfort": "温度适宜",
			"air_quality": "空气质量好",
			"cleanliness": "干净整洁",
			"light": "光照好",
			"daylight": "采光好",
			"location": "地点",
			"other_complaints": "其他缺陷",
			"other_location": "所评论的区域（例如：大堂、二楼东侧、50层的500号房间)",
			"comments": "意见（选填)",
			"submit_text": "提交...",
			"ok": "不错/不錯",
			"error_sending_survey": "請填寫運輸數據以便能夠提交調查",
			"acceptnclose": "接受和關閉",
			"cookies_msg_one": "本网站收集和使用cookies以确保您获得最佳用户体验。",
			"cookies_msg_two": '請點擊“接受並關閉”按鈕以確認您的同意並繼續使用我們的網站。欲了解更多信息，請查看我們的',
			"cookies_msg_three": '餅乾聲明',
			"save": "保存",
			"cancel": "取消",
			"travel_method": "通勤方式",
			"distance_in": "距离单位：",
			"clear": "清除",
			"modal_message": "包括您前往大楼途径的所有路程.",
			"modal_message_si": "包括您前往大楼途径的所有路程.",
			"neutral": "没感觉",
			"satisfaction_none": "既不滿意也不滿足",
			"satisfied": "满意",
			"very_satisfied": "很满意",
			"unsatisfied": "有点不满意",
			"very_unsatisfied": "很不满意",
			"about_arc": "關於 弧",
			"arc_policy": "隱私政策",
			"thank_you_message":"感謝您參加我們的調查！你的回答：",
			"transportation":"運輸",
			"enhance_message": "提高您滿意度的選項",
			"reduce_message": "降低您滿意度的選項",
			"lobby_page_thankyou": "感謝您參加我們的調查！",
			"start_survey": "開始新的調查",
			"high_mileage": "高里程",
			"slide_to_select": "滑动到您的选择",
			"ignore": "忽视",
			"occupant_type_question": "您在此建筑中的身份",
			"occupant_type": "选择身份",
			"regular": "普通居民",
			"visitor": "遊客",
			"submit_error": "请回答所有问题",
			"next": "下一个",
			"skip": "跳过",
			"previous": "以前的",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "提交以下一项或两项",
			"Number of days in a week, you use this route": "一周中您使用此路线的天数",
			"day": "天",
			"visitorRouteError": "访客只能添加 1 条路线。"
		},
		"swedish":{
			"survey": "Enkät",
			"transport":"Transport",
			"typical_day": 'Hur tar man sig vanligtvis till den här byggnaden? Om dina resesätt varierar beroende på veckodag (inklusive distansarbete), ange en rutt för varje dag. Om du är en besökare, ange en rutt för hur du reste till den här byggnaden.',
			"typical_day_v1": 'Hur brukar du ta dig till och från jobbet under en typisk dag? I ditt svar – ange resan i en riktning för en dag',
			"one_day": "Om dina transportmetoder varierar beroende på veckodag eller färdriktning anger du flera rutter",
			"route": "Sträcka",
			"select_travel": "Välj resealternativ",
			"mandatory_field_error": "Detta är en obligatorisk fråga",
			"blank_satisfaction_error": "Nödvändig fråga.Använd skjutreglaget för att göra ett val.",
			"walk_bike_telecommute": "Gång, cykel, distansarbete",
			"walk": "Gång",
			"bike": "Cykel eller elsparkcykel ",
			"telecommute": "distansarbete",
			"bus": "Buss",
			"light_rail": "Spårvagn",
			"heavy_rail": "Tunnelbana eller pendeltåg",
			"motorcycle": "Motorcykel, moped eller scooter",
			"car": "Bil, själv",
			"car23": "Samåkning 2-3 personer/Vanpool",
			"cars4": "Bil, alternativa drivmedel",
			"kilometers": "Kilometer",
			"miles": "Miles",
			"add_route": "Lägg till ytterligare sträcka",
			"remove_route": "Ta bort rutten",
			"satisfaction": "Nöjdhet",
			"how_satisfied": "1. Hur nöjd är du med inomhusklimatet i den här byggnaden?",
			"how_satisfied_v1": "Betygsätt din upplevelse genom att dra i reglaget",
			"extremely_unsatisfied":"Extremt missnöjd",
			"extremely_satisfied": "Extremt nöjd",
			"name": "Namn (valfritt)",
			"submit": "Skicka",
			"compliment_message": "2. Kul att höra. Vilka av nedan alternativ förbättrar din upplevelse avsevärt?",
			"compliment_message_v1": "Kul att höra. Vilka av nedan alternativ förbättrar din upplevelse avsevärt?",
			"sorry_message": "2. Det var tråkigt att höra. Vänligen välj anledning nedan som påverkar din nöjdhet:",
			"sorry_message_v1": "Det var tråkigt att höra. Vänligen välj anledning nedan som påverkar din nöjdhet:",
			"dirty": "Smutsigt",
			"smelly": "Lukt",
			"stuffy": "Kvavt",
			"loud": "Akustik",
			"loud_v2": "Högljutt",
			"hot": "Varmt",
			"cold": "Kallt",
			"dark": "Mörkt",
			"glare": "Bländning",
			"privacy": "Avskildhet",
			"privacy_satisfied": "Avskildhet",
			"drafty": "dragig",
			"bright": "Ljus",
			"views": "Utomhusutsikt",
			"views_satisfied": "Utomhusutsikt",
			"sound": "Ljud",
			"sound_satisfied": "Ljud",
			"humid": "Fuktig",
			"thermal_comfort": "Inomhustemperatur (termisk komfort)",
			"air_quality": "Luftkvalitet",
			"cleanliness": "Renlighet",
			"light": "Belysning",
			"daylight": "Dagsljus",
			"location": "Plats",
			"other_complaints": "Andra klagomål",
			"other_location": "Var i huset sitter du? t.ex. lobby",
			"comments": "Kommentarer (Valfritt)",
			"submit_text": "Skickar ...",
			"ok": "OK",
			"error_sending_survey": "Vänligen fyll i transportdata för att kunna lämna in undersökningen",
			"acceptnclose": "ACCEPT OCH CLOSE",
			"cookies_msg_one": "Den här webbplatsen samlar in och använder cookies för att du ska få den bästa användarupplevelsen.",
			"cookies_msg_two": 'Vänligen klicka på "Acceptera och Stäng" för att bekräfta ditt samtycke och fortsätt använda vår hemsida. För mer information, se vår',
			"cookies_msg_three": 'Cookies uttalande',
			"save": "SPARA",
			"cancel": "ANNULLERA",
			"travel_method": "Rese Metod",
			"distance_in": "Avstånd i",
			"clear": "Klar",
			"modal_message": "Antal mile du reser för att komma till denna byggnad",
			"modal_message_si": "Antal kilometer du reser för att komma till denna byggnad",
			"neutral": "Varken nöjda inte missnöjda",
			"satisfaction_none": "Varken nöjd eller missnöjd",
			"satisfied": "Nöjd",
			"very_satisfied": "Väldigt Nöjd",
			"unsatisfied": "Otillfredsställd",
			"very_unsatisfied": "Mycket missnöjd",
			"about_arc": "Om Arc",
			"arc_policy": "Integritetspolicy",
			"thank_you_message":"Tack för att du har gjort vår enkät! Dina svar",
			"transportation":"Transportation",
			"enhance_message": "Alternativ som förbättrar din tillfredsställelse",
			"reduce_message": "Alternativ som minskar din tillfredsställelse",
			"lobby_page_thankyou": "Tack för att du har gjort vår enkät!",
			"start_survey": "Börja en ny undersökning",
			"high_mileage": "Hög körsträcka",
			"slide_to_select": "Betygsätt din upplevelse genom att dra i reglaget",
			"ignore": "Ignorera",
			"occupant_type_question": "Är du hyresgäst eller besökare in huset?",
			"occupant_type": "Välj Occupant Type",
			"regular": "Hyresgäst",
			"visitor": "Besökare",
			"submit_error": "Vänligen svara på alla frågor",
			"next": "Nästa",
			"skip": "Hoppa",
			"previous": "Tidigare",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "Skicka in en eller båda av följande",
			"Number of days in a week, you use this route": "Antal dagar i veckan använder du den här rutten",
			"day": "dag",
			"visitorRouteError": "Besökare kan bara lägga till en rutt."
		},
		"arabic":{
			"survey": "استبيان",
			"transport":"طريقة الذهاب إلى العمل",
			"typical_day": 'كيف تصل عادةً إلى هذا المبنى؟ إذا كانت طرق سفرك تختلف حسب يوم الأسبوع (بما في ذلك العمل عن بعد)، فأدخل مسارًا لكل يوم. إذا كنت زائرًا، فأدخل طريقًا واحدًا يوضح كيفية سفرك إلى هذا المبنى.',
			"typical_day_v1": 'ما هي الطريقة التي تستخدمها للوصول إلى هذا المبنى والمغادرة منه في الأيام العادية؟ أدخل النتائج ليوم واحد واتجاه واحد',
			"one_day": "إذا كانت وسائل تنقلك تختلف بحسب أيام الأسبوع أو اتجاه السفر، يُرجى إدخال طُرق مُتعددة",
			"route": "المسار",
			"select_travel": "اختر طريقة السفر",
			"mandatory_field_error": "هذا السؤال إلزامي",
			"blank_satisfaction_error": "سؤال إلزامي. استخدم شريط التمرير للاختيار.",
			"walk_bike_telecommute": "سيرًا, بالدراجة, أعمل من المنزل",
			"walk": "سيرًا",
			"bike": "دراجات بخارية أو دراجات كهربائية",
			"telecommute": "أعمل من المنزل",
			"bus": "بالحافلة",
			"light_rail": "بالقطار الخفيف",
			"heavy_rail": "بالقطار السريع",
			"motorcycle": "بالدراجة النارية أو بالسكوتر",
			"car": "بسيارة بمفردي",
			"car23": "مرافقي السيارات 2-3 أشخاص / فانبول",
			"cars4": "بسيارة تعمل بالوقود البديل",
			"kilometers": "كيلومتر",
			"miles": "ميل",
			"add_route": "أضف مسارًا آخرًا",
			"remove_route": "إزالة مسار",
			"satisfaction": "الرضا",
			"how_satisfied": "1. ٢. استخدم شريط التمرير لتوضيح مدى رضاك عن البيئة في هذا المبنى",
			"how_satisfied_v1": "استخدم شريط التمرير لتوضيح مدى رضاك عن البيئة في هذا المبنى",
			"extremely_unsatisfied":"غير راضٍ تمامًا",
			"extremely_satisfied": "راضٍ تمامًا",
			"name": "الاسم (اختياري)",
			"submit": "تقديم",
			"compliment_message": "2. ٣. يسعدنا سماع ذلك. يُرجى تحديد الاختيارات التالية التي تزيد من مستوى رضاك بشكل كبير:",
			"compliment_message_v1": "يسعدنا سماع ذلك. يُرجى تحديد الاختيارات التالية التي تزيد من مستوى رضاك بشكل كبير:",
			"sorry_message": "2. ٣. نأسف لسماع ذلك. يُرجى تحديد الاختيارات التالية التي تُقلل من مستوى رضاك بشكل كبير:",
			"sorry_message_v1": "نأسف لسماع ذلك. يُرجى تحديد الاختيارات التالية التي تُقلل من مستوى رضاك بشكل كبير:",
			"dirty": "مُتسخة",
			"drafty": "التهوية",
			"bright": "الإضاءة",
			"views": "الإطلالات على خارج المبنى",
			"views_satisfied": "الإطلالات على خارج المبنى",
			"sound": "الصوت",
			"sound_satisfied": "الصوت",
			"humid": "الرطوبة",
			"smelly": "كريهة الرائحة",
			"stuffy": "غير جيدة التهوية",
			"loud": "الصوت",
			"loud_v2": "مزعجة",
			"hot": "حارة",
			"cold": "باردة",
			"dark": "مُظلمة",
			"glare": "متوهجة",
			"privacy": "الخصوصية",
			"privacy_satisfied": "الخصوصية",
			"thermal_comfort": "الراحة الحرارية",
			"air_quality": "جودة الهواء",
			"cleanliness": "النظافة",
			"light": "الضوء",
			"daylight": "ضوء النهار",
			"location": "المكان",
			"comments": "ملاحظات (اختياري)",
			"other_complaints": "شكاوى أخرى",
			"other_location": "المكان، مثل الردهة، الطابق الثاني شرق، الطابق الخمسون، الجناح ٥٠٠",
			"submit_text": "جاري التقديم...",
			"ok": "حسنًا",
			"error_sending_survey": "يُرجى تعبئة بيانات التنقل حتى تتمكن من تقديم الاستبيان",
			"thankyou_message": "شكراً جزيلاً على وقتك.",
			"reading_error_survey": "",
			"acceptnclose": "قبول وإغلاق",
			"cookies_msg_one": "يقوم هذا الموقع الإلكتروني باستخدام ملفات تعريف الارتباط (كوكيز) لضمان تقديم أفضل تجربة للمستخدم.",
			"cookies_msg_two": 'يُرجى النقر على زر "قبول وإغلاق" لتأكيد موافقتك والاستمرار في استخدام موقعنا الإلكتروني. للمزيد من المعلومات، يُرجى الاطلاع على',
			"cookies_msg_three": 'بيان ملفات تعريف الارتباط',
			"save": "حفظ",
			"cancel": "إلغاء",
			"travel_method": "طريقة التنقل",
			"distance_in": "المسافة بـ",
			"clear": "مسح",
			"modal_message": "أضف الأميال التي تقطعها حتى تصل إلى هذا المبنى.",
			"modal_message_si": "أضف الكيلومترات التي تقطعها حتى تصل إلى هذا المبنى.",
			"neutral": "لست راضيًا أو غير راضٍ",
			"satisfaction_none": "لست راضيًا أو غير راضٍ",
			"satisfied": "راضٍ",
			"very_satisfied": "راضٍ جداً",
			"unsatisfied": "غير راضٍ",
			"very_unsatisfied": "غير راضٍ إطلاقًا",
			"about_arc": "نبذة عن Arc",
			"arc_policy": "سياسة الخصوصية",
			"thank_you_message":"نشكرك لاهتمامك بتعبئة الاستبيان! إجاباتك",
			"transportation":"التنقل",
			"enhance_message": "اختيارات تزيد من مستوى رضاك",
			"reduce_message": "اختيارات تُقلل من مستوى رضاك",
			"lobby_page_thankyou": "نشكرك لاهتمامك بتعبئة الاستبيان!",
			"start_survey": "ابدأ استبيانًا جديدًا",
			"high_mileage": "مسافة كبيرة",
			"slide_to_select": "انتقل إلى اختيارك",
			"ignore": "تجاهل",
			"occupant_type_question": "ما نوع إشغالك للمكان؟",
			"occupant_type": "اختر نوع الإشغال",
			"regular": "إشغال عادي",
			"visitor": "زيارة",
			"submit_error": "يُرجى الإجابة عن جميع الأسئلة.",
			"next": "التالي",
			"skip": "يتخطى",
			"previous": "سابق",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "أرسل واحدًا أو كليهما مما يلي",
			"Number of days in a week, you use this route": "عدد أيام الأسبوع الذي تستخدمه في هذا الطريق",
			"day": "يوم",
			"visitorRouteError": "يمكن للزائرين إضافة طريق واحد فقط."
		},
		"finnish":{
			"survey": "Kysely",
			"transport":"Liikenne",
			"typical_day": 'Miten tähän rakennukseen yleensä pääsee? Jos matkustustapasi vaihtelevat viikonpäivien mukaan (mukaan lukien etätyö), syötä reitti kullekin päivälle. Jos olet Vierailija, anna yksi reitti, jolla matkustit tähän rakennukseen.',
			"typical_day_v1": 'Kuinka kuljet kohteeseen tyypillisenä päivänä? Ilmoita yhden päivän yhdensuuntaisen matkan tiedot.',
			"one_day": "Jos kulkemistapasi vaihtelevat viikonpäivän tai kulkusuunnan mukaan, lisää usemapi reitti",
			"route": "reitti",
			"select_travel": "Valitse kulkutapa",
			"mandatory_field_error": "Tämä on pakollinen kysymys",
			"blank_satisfaction_error": "Pakollinen kysymys. Käytä liukusäädintä valitaksesi vastauksesi.",
			"walk_bike_telecommute": "Kävely, Polkupyörä, Etätyö",
			"walk": "Kävely",
			"bike": "Pyörä tai sähkö skootterit",
			"telecommute": "Etätyö",
			"bus": "Bussi",
			"light_rail": "Metro",
			"heavy_rail": "Juna",
			"motorcycle": "Moottoripyörä or skootteri",
			"car": "Autolla, yksin",
			"car23": "Carpool 2-3 henkilöä/vanpool",
			"cars4": "Autolla, vaihoehtoinen polttoaine",
			"kilometers": "Kilometri",
			"miles": "Maili",
			"add_route": "Lisää toinen reitti",
			"remove_route": "Poista reitti",
			"satisfaction": "Viihtyvyys",
			"how_satisfied": "1. Käytä liukusäädintä valitaksesi kuinka tyytyväinen olet työympäristöösi rakennuksessa",
			"how_satisfied_v1": "Käytä liukusäädintä valitaksesi kuinka tyytyväinen olet työympäristöösi rakennuksessa",
			"extremely_unsatisfied":"Erittäin tyytymätön",
			"extremely_satisfied": "Erittäin tyytyväinen",
			"name": "Nimi (Valinnainen)",
			"submit": "Lähetä",
			"compliment_message": "2. Hienoa kuulla. Valitse alla olevista vaihtoehdot, jotka lisäävät tyytyväisyyttäsi merkittävästi:",
			"compliment_message_v1": "Hienoa kuulla. Valitse alla olevista vaihtoehdot, jotka lisäävät tyytyväisyyttäsi merkittävästi:",
			"sorry_message": "2. Ikävä kuulla. Valitse alla olevaista vaihtoehdot, jotka vähentävät tyytyväisyyttäsi:",
			"sorry_message_v1": "Ikävä kuulla. Valitse alla olevaista vaihtoehdot, jotka vähentävät tyytyväisyyttäsi:",
			"dirty": "Lika",
			"drafty": "Vetoisuus",
			"bright": "Kirkkaus",
			"views": "Näkymät ulos",
			"views_satisfied": "Näkymät ulos",
			"sound": "Äänet",
			"sound_satisfied": "Äänet",
			"humid": "Kosteus",
			"smelly": "Pahanhajuisuus",
			"stuffy": "Tunkkaisuus",
			"loud": "Akustiikka",
			"loud_v2": "äänekkyys",
			"hot": "Kuuma",
			"cold": "Kylmä",
			"dark": "Pimeys",
			"glare": "Heijastukset",
			"privacy": "Yksityisyys",
			"privacy_satisfied": "Yksityisyys",
			"thermal_comfort": "Lämpöolosuhteet",
			"air_quality": "Ilmanlaatu",
			"cleanliness": "Siisteys",
			"light": "Valoisuus",
			"daylight": "Päivänvalo",
			"location": "Sijainti",
			"comments": "Kommentit (valinnainen)",
			"other_complaints": "Muut huolenaiheet",
			"other_location": "Sijainti, esim. aula tai toinen kerros",
			"submit_text": "Lähetetään…",
			"ok": "OK",
			"error_sending_survey": "Täytä kulkemistiedot voidaksesi lähettää kyselyn",
			"thankyou_message": "Kiitos vastauksistasi.",
			"reading_error_survey": "",
			"acceptnclose": "Hyväksy ja sulje",
			"cookies_msg_one": "Tämä sivu kerää ja käyttää evästeitä parhaan käyttökokemuksen varmistamiseksi.",
			"cookies_msg_two": "Napsauta ''Hyväksy ja sulje'' -painiketta vahvistaaksesi suostumuksesi ja jatkaaksesi verkkosivustomme käyttöä. Lisätietoa saat:",
			"cookies_msg_three": 'Selvitys evästeistä',
			"save": "Tallenna",
			"cancel": "Peruuta",
			"travel_method": "Kulkutapa",
			"distance_in": "Etäisyys:",
			"clear": "Tyhjennä",
			"modal_message": "Sisällytä kaikki mailit, jotka matkustat päästäksesi rakennukseen.",
			"modal_message_si": "Sisällytä kaikki kilometrit, jotka matkustat päästäksesi rakennukseen.",
			"neutral": "Neutraali",
			"satisfaction_none": "Neutraali",
			"satisfied": "Osittain tyytyväinen",
			"very_satisfied": "Tyytyväinen",
			"unsatisfied": "Osittain tyytymätön",
			"very_unsatisfied": "Tyytymätön",
			"about_arc": "Tietoa Arc:sta",
			"arc_policy": "Tietosuojakäytäntö",
			"thank_you_message":"Kiitos vastaamisesta! Vastauksesi:",
			"transportation":"Liikenneväline",
			"enhance_message": "Vaihtoehdot, jotka lisäävät tyytyväisyyttäsi",
			"reduce_message": "Vaihtoehdot, jotka vähentävät tyytyväisyyttäsi",
			"lobby_page_thankyou": "Kiitos vastaamisesta!",
			"start_survey": "Aloita uusi kysely",
			"high_mileage": "Korkea maililukema",
			"slide_to_select": "Liu'uta vastaukseesi",
			"ignore": "Jätä huomiotta",
			"occupant_type_question": "Mikä käyttäjätyyppi kuvaa sinua parhaiten?",
			"occupant_type": "Valitse käyttäjätyyppi",
			"regular": "Säännöllinen käyttäjä, työntekijä",
			"visitor": "Vierailija",
			"submit_error": "Vastaathan kaikkiin kysymyksiin",
			"next": "Seuraava",
			"skip": "Ohita",
			"previous": "Edellinen",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "Lähetä toinen tai molemmat seuraavista",
			"Number of days in a week, you use this route": "Päivien lukumäärä viikossa, kun käytät tätä reittiä",
			"day": "päivä",
			"visitorRouteError": "Vierailijat voivat lisätä vain yhden reitin."
		},
		"russian":{
			"survey": "Опрос",
			"transport":"Транспорт",
			"typical_day": 'Как вы обычно добираетесь до этого здания? Если ваши способы передвижения различаются в зависимости от дня недели (включая удаленную работу), введите маршрут на каждый день. Если вы Посетитель, укажите один маршрут, по которому вы добирались до этого здания.',
			"typical_day_v1": 'Как вы обычно добираетесь до здания и от него? Внесите как вы обчно добираетесь в это здание.',
			"one_day": "Если ваши способы передвижения отличаются в зависимости от дня недели или направления движения, то укажите несколько маршрутов",
			"route": "маршрут",
			"select_travel": "Выбери метод передвижения",
			"mandatory_field_error": "Это обязательный вопрос",
			"blank_satisfaction_error": "Обязательный вопрос. Используйте ползунок для того, чтобы выбрать.",
			"walk_bike_telecommute": "ходьба, велосипед, дистанционная работа",
			"walk": "ходьба",
			"bike": "Велосипед или электросамокат",
			"telecommute": "дистанционная работа",
			"bus": "автобус",
			"light_rail": "скоростной трамвай",
			"heavy_rail": "метро, электричка",
			"motorcycle": "мотоцикл или скутер",
			"car": "на машине один",
			"car23": "Автобаза 2-3 человека/ванпул",
			"cars4": "машина на альтернативном топливе (газ, эл-во)",
			"kilometers": "Километр",
			"miles": "Миля",
			"add_route": "Добавить другие маршруты",
			"remove_route": "Удалить маршрут",
			"satisfaction": "Удовлетворенность",
			"how_satisfied": "1. Используйте ползунок, чтобы указать, насколько вы удовлетворены окружающей средой в этом здании",
			"how_satisfied_v1": "Используйте ползунок, чтобы указать, насколько вы удовлетворены окружающей средой в этом здании",
			"extremely_unsatisfied":"Крайне не доволен(льна)",
			"extremely_satisfied": "Крайне доволен(льна)",
			"name": "имя (необязательно)",
			"submit": "отправить",
			"compliment_message": "2. Мы рады это слышать. Пожалуйста, выберите факторы, которые вам больше всего нравятся:",
			"compliment_message_v1": "Мы рады это слышать. Пожалуйста, выберите факторы, которые вам больше всего нравятся.:",
			"sorry_message": "2. Нам очень жаль это слышать. Пожалуйста, выберите факторы, которые вас беспокоят:",
			"sorry_message_v1": "Нам очень жаль это слышать. Пожалуйста, выберите факторы, которые вас беспокоят:",
			"dirty": "грязно",
			"drafty": "Сквозняк",
			"bright": "Ярко",
			"views": "Вид на улицу/Вид из окна",
			"views_satisfied": "Вид на улицу/Вид из окна",
			"sound": "Звук",
			"sound_satisfied": "Звук",
			"humid": "Влажность",
			"smelly": "плохо пахнет",
			"stuffy": "душно",
			"loud": "Акустика",
			"loud_v2": "громко",
			"hot": "жарко",
			"cold": "холодно",
			"dark": "темно",
			"glare": "яркий свет",
			"privacy": "отсутствие должной приватности",
			"privacy_satisfied": "отсутствие должной приватности",
			"thermal_comfort": "Тепловой комфорт",
			"air_quality": "Качество воздуха",
			"cleanliness": "Чистота",
			"light": "Светло",
			"daylight": "Дневное освещение",
			"location": "Расположение",
			"comments": "Комментарии (необязательно)",
			"other_complaints": "другие жалобы",
			"other_location": "Расположение - например Вестибюль, 2-й этаж, воточное крыло, 50-й этаж - офис 500",
			"submit_text": "Отправка...",
			"ok": "Ок",
			"error_sending_survey": "Пожалуйста, заполните данные по транспорту, чтобы отправить опрос",
			"thankyou_message": "Спасибо за уделенное время",
			"reading_error_survey": "",
			"acceptnclose": "Принять и закрыть",
			"cookies_msg_one": "Этот веб-сайт собирает и использует Cookie (куки) файлы для максимального удобства пользователя.",
			"cookies_msg_two": 'Нажмите кнопку «Принять и закрыть», чтобы подтведить свое согласие и продолжить использования нашего веб-сайта. Для получения дополнительной информации, пожалуйста, просмотрите',
			"cookies_msg_three": 'Заявление (отчет) о Cookie (куки)',
			"save": "Сохранить",
			"cancel": "Отменить",
			"travel_method": "Метод поездки",
			"distance_in": "Расстояние в:",
			"clear": "Очистить",
			"modal_message": "Укажите все мили, которые вы преодолеваете до здания.",
			"modal_message_si": "Укажите все километры, которые вы преодолеваете до здания.",
			"neutral": "Ни доволен, ни неудовлетворен",
			"satisfaction_none": "Ни доволен, ни неудовлетворен",
			"satisfied": "Удовлетворен (а)",
			"very_satisfied": "Крайне удовлетворен(а)",
			"unsatisfied": "Не удовлетворен(а)",
			"very_unsatisfied": "Крайне не удовлетворен(а)",
			"about_arc": 'Об "Arc"',
			"arc_policy": "Политика конфиденциальности",
			"thank_you_message":"Спасибо за участие в нашем опросе. Ваши ответы:",
			"transportation":"Транспортировка",
			"enhance_message": "Опции, которые повышают вашу удовлетворенность",
			"reduce_message": "Опции, которые ухудшают вашу удовлетворенность",
			"lobby_page_thankyou": "Спасибо за участие в нашем опросе!",
			"start_survey": "Начать новый опрос",
			"high_mileage": "Большой ",
			"slide_to_select": "Двиньте ползунок для вашего выбора",
			"ignore": "Игнорировать",
			"occupant_type_question": "Какой вы посетитель?",
			"occupant_type": "Выберите тип пользователя",
			"regular": "Постоянный пользователь",
			"visitor": "Временный посетитель",
			"submit_error": "Пожалуйста, ответьте на все вопросы",
			"next": "Следующий",
			"skip": "Пропускать",
			"previous": "Предыдущий",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "Отправьте одно или оба из следующих",
			"Number of days in a week, you use this route": "Количество дней в неделе, когда вы пользуетесь этим маршрутом",
			"day": "день",
			"visitorRouteError": "Посетители могут добавить только 1 маршрут."
		},
		"italian":{
			"survey": "Sondaggio",
			"transport":"Trasporto",
			"typical_day": "Come arrivi di solito a questo edificio? Se i tuoi metodi di viaggio variano in base al giorno della settimana (incluso il telelavoro), inserisci un percorso per ciascun giorno. Se sei un visitatore, inserisci un percorso relativo al modo in cui hai raggiunto questo edificio.",
			"typical_day_v1": "Secondo la tua abitudine più comune, come raggiungi l'edificio?",
			"one_day": "Se la tua modalità di trasporto varia durante la settimana, inserisci più percorsi",
			"route": "Tratta",
			"select_travel": "Seleziona il metodo di viaggio",
			"mandatory_field_error": "Domanda obbligatoria",
			"blank_satisfaction_error": "Domanda obbligatoria. Usa il cursore per effettuare una selezione",
			"walk_bike_telecommute": "A piedi, Bicicletta, Telelavoro",
			"walk": "A piedi",
			"bike": "Bici o scooter elettrici",
			"telecommute": "Telelavoro",
			"bus": "Autobus",
			"light_rail": "Tranvia veloce (ogni 3-5') ",
			"heavy_rail": "Metropolitana",
			"motorcycle": "Moto",
			"car": "Auto propria (no carpool)",
			"car23": "Carpool 2-3 persone/Vanpool",
			"cars4": "Auto alimentata tramite fonti alternative",
			"kilometers": "Chilometri",
			"miles": "Miglia",
			"add_route": "Aggiungi un'altra tratta",
			"remove_route": "Rimuovi il percorso",
			"satisfaction": "Livello di Soddisfazione",
			"how_satisfied": "1. Usa il cursore per indicare quanto sei soddisfattodelle condizioni ambientali di lavoro nell'edificio",
			"how_satisfied_v1": "Usa il cursore per indicare quanto sei soddisfattodelle condizioni ambientali di lavoro nell'edificio",
			"extremely_unsatisfied":"Estremamente insoddisfatto",
			"extremely_satisfied": "Estremamente soddisfatto",
			"name": "Nome (facoltativo)",
			"submit": "Invia",
			"compliment_message": "2. Siamo contenti! Per favore, seleziona le ragioni fra le seguenti opzioni che aumentano significativamente la tua soddisfazione:",
			"compliment_message_v1": "Siamo contenti! Per favore, seleziona le ragioni fra le seguenti opzioni che aumentano significativamente la tua soddisfazione:",
			"sorry_message": "2. Siamo spiacenti di questo feedback. Si prega di selezionare le cause, fra le seguenti, che riducono significativamente la tua soddisfazione:",
			"sorry_message_v1": "Siamo spiacenti di questo feedback. Si prega di selezionare le cause, fra le seguenti, che riducono significativamente la tua soddisfazione:",
			"dirty": "Sporco",
			"drafty": "Spifferi",
			"bright": "Luminoso",
			"views": "Vista verso l'esterno",
			"views_satisfied": "Vista verso l'esterno",
			"sound": "Suoni",
			"sound_satisfied": "Suoni",
			"humid": "Umidità",
			"smelly": "Maleodorante ",
			"stuffy": "Soffocante",
			"loud": "Acustica",
			"loud_v2": "Rumoroso",
			"hot": "Caldo",
			"cold": "Freddo",
			"dark": "Buio",
			"glare": "Abbagliante",
			"privacy": "Privacy",
			"privacy_satisfied": "Privacy",
			"thermal_comfort": "Comfort termico",
			"air_quality": "Qualità dell'aria",
			"cleanliness": "Pulizia",
			"light": "Luce",
			"daylight": "Luce naturale",
			"location": "Posizione all'interno dell'edificio",
			"comments": "Commenti (facoltativo)",
			"other_complaints": "Altri reclami",
			"other_location": "Posizione - es. Lobby, 2° piano est, 50° piano - Stanza 500",
			"submit_text": "Invio in corso",
			"ok": "OK",
			"error_sending_survey": "Si prega di compilare i dati di trasporto per poter concludere il sondaggio",
			"thankyou_message": "Grazie per il tempo dedicato",
			"reading_error_survey": "",
			"acceptnclose": "ACCETTA E CHIUDI",
			"cookies_msg_one": "Questo sito web raccoglie e utilizza i cookie per garantire una migliore esperienza dell'utente.",
			"cookies_msg_two": 'Per favore, clicca su "Accetta e chiudi" per confermare il tuo consenso e continuare ad utilizzare il nostro sito web. Per ulteriori informazioni, si prega di visualizzare il nostro',
			"cookies_msg_three": 'Dichiarazione sui cookie',
			"save": "SALVA",
			"cancel": "CANCELLA",
			"travel_method": "Metodo di Traporto",
			"distance_in": "Distanza In:",
			"clear": "Cancella",
			"modal_message": "Includi tutte le miglia che percorri per raggiungere questo edificio",
			"modal_message_si": "Includi tutti i chilometri che percorri per raggiungere questo edificio",
			"neutral": "Né soddisfatto né insoddisfatto",
			"satisfaction_none": "Né soddisfatto né insoddisfatto",
			"satisfied": "Livello di Soddisfazione",
			"very_satisfied": "Molto soddisfatto",
			"unsatisfied": "Insoddisfatto",
			"very_unsatisfied": "Molto insoddisfatto",
			"about_arc": "Informazioni su Arc",
			"arc_policy": "Politica sulla privacy",
			"thank_you_message":"Grazie per aver partecipato al nostro sondaggio! Le vostre risposte",
			"transportation":"Trasporto",
			"enhance_message": "Opzioni che migliorano la vostra soddisfazione",
			"reduce_message": "Opzioni che riducono la vostra soddisfazione",
			"lobby_page_thankyou": "Grazie per aver partecipato al nostro sondaggio!",
			"start_survey": "Iniziare un nuovo sondaggio",
			"high_mileage": "Alta percorrenza",
			"slide_to_select": "Scorri fino alla tua selezione",
			"ignore": "Ignora",
			"occupant_type_question": "Che tipo di occupante sei?",
			"occupant_type": "Seleziona tipologia di occupazione",
			"regular": "Occupante regolare",
			"visitor": "Visitatore",
			"submit_error": "Per favore rispondi a tutte le domande",
			"next": "Prossima",
			"skip": "Saltare",
			"previous": "Precedente",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "Invia uno o entrambi i seguenti",
			"Number of days in a week, you use this route": "Numero di giorni in una settimana in cui utilizzi questo percorso",
			"day": "giorno",
			"visitorRouteError": "I visitatori possono aggiungere solo 1 percorso."
		},
		"german":{
			"survey": "Umfrage",
			"transport":"Verkehrsmittel",
			"typical_day": 'Wie kommt man normalerweise zu diesem Gebäude? Wenn Ihre Reisemethoden je nach Wochentag variieren (einschließlich Telearbeit), geben Sie für jeden Tag eine Route ein. Wenn Sie ein Besucher sind, geben Sie eine Route ein, wie Sie zu diesem Gebäude gereist sind.',
			"typical_day_v1": 'Wie kommen Sie an einem typischen Tag zu oder von diesem Gebäude? Geben Sie die Ergebnisse für einen einfachen Weg an einem Tag ein.',
			"one_day": "Wenn Ihr Transportmittel oder -weg je nach Wochentag oder Reiserichtung variiert, geben Sie mehrere Routen ein.",
			"route": "Wegstrecke",
			"select_travel": "Wählen Sie ein Transportmittel",
			"mandatory_field_error": "Dies ist eine erforderliche Frage",
			"blank_satisfaction_error": "Erforderliche Frage. Verwenden Sie den Schieberegler, um eine Auswahl zu treffen.",
			"walk_bike_telecommute": "zu Fuß, Fahrrad, Telearbeit/Home Office",
			"walk": "zu Fuß",
			"bike": "Fahrrad oder Elektroroller",
			"telecommute": "Telearbeit / Home Office",
			"bus": "Bus",
			"light_rail": "Straßenbahn / U-Bahn / S-Bahn",
			"heavy_rail": "Regionalzüge / Fernverkehrszüge",
			"motorcycle": "Motorrad / Motorroller",
			"car": "Auto allein",
			"car23": "Fahrgemeinschaft 2-3 Personen/Vanpool",
			"cars4": "Auto mit alternativem Antrieb",
			"kilometers": "Kilometer",
			"miles": "Meile",
			"add_route": "Eine weitere Strecke hinzufügen",
			"remove_route": "Route entfernen",
			"satisfaction": "Zufriedenheit",
			"how_satisfied": "1. Verwenden Sie den Schieberegler, um anzugeben, wie zufrieden Sie mit den Bedingungen in diesem Gebäude sind.",
			"how_satisfied_v1": "Verwenden Sie den Schieberegler, um anzugeben, wie zufrieden Sie mit den Bedingungen in diesem Gebäude sind.",
			"extremely_unsatisfied":"Äußerst unzufrieden",
			"extremely_satisfied": "Äußerst zufrieden",
			"name": "Name (Optional)",
			"submit": "Einreichen",
			"compliment_message": "2. Wir sind froh das zu hören. Bitte wählen Sie die Bedingungen, die Ihre Zufriedenheit deutlich steigern:",
			"compliment_message_v1": "Wir sind froh das zu hören. Bitte wählen Sie die Bedingungen, die Ihre Zufriedenheit deutlich steigern:",
			"sorry_message": "2. Wir bedauern dies zu hören. Bitte wählen Sie die Bedingungen, die Ihre Zufriedenheit deutlich reduzieren:",
			"sorry_message_v1": "Wir bedauern dies zu hören. Bitte wählen Sie die Bedingungen, die Ihre Zufriedenheit deutlich reduzieren:",
			"dirty": "Mangelnde Sauberkeit",
			"drafty": "Zugig",
			"bright": "Hell",
			"views": "Ausblicke nach draußen",
			"views_satisfied": "Ausblicke nach draußen",
			"sound": "Hintergrundgeräusche",
			"sound_satisfied": "Hintergrundgeräusche",
			"humid": "Zu hohe Raumfeuchte ",
			"smelly": "Geruchsbelästigung",
			"stuffy": "Stickig",
			"loud": "Schlechte Raumakustik",
			"loud_v2": "Laut",
			"hot": "Zu hohe Raumtemperatur",
			"cold": "Zu niedrige Raumtemperatur",
			"dark": "Mangelnde Beleuchtung",
			"glare": "Blendung",
			"privacy": "Privatsphäre",
			"privacy_satisfied": "Privacy",
			"thermal_comfort": "Wärmekomfort",
			"air_quality": "Luftqualität",
			"cleanliness": "Sauberkeit",
			"light": "Licht",
			"daylight": "Tageslicht",
			"location": "Lage",
			"comments": "Kommentare (optional)",
			"other_complaints": "andere Beschwerden",
			"other_location": "Standort (Beispiel: Lobby)",
			"submit_text": "Einreichen...",
			"ok": "OK",
			"error_sending_survey": "Bitte füllen Sie die Transportdaten aus, um die Umfrage einreichen zu können.",
			"thankyou_message": "Vielen Dank für Ihre Teilnahme.",
			"reading_error_survey": "",
			"acceptnclose": "AKZEPTIEREN UND SCHLIESSEN",
			"cookies_msg_one": "Diese Website sammelt und verwendet Cookies, um sicherzustellen, dass Sie die beste Nutzererfahrung erhalten.",
			"cookies_msg_two": 'Bitte klicken Sie auf "Akzeptieren und schließen", um Ihre Zustimmung zu bestätigen und weiterhin unsere Website zu nutzen. Weitere Informationen finden Sie in unserer ',
			"cookies_msg_three": 'Cookie-Erklärung',
			"save": "SPEICHERN",
			"cancel": "ABBRECHEN",
			"travel_method": "Transportmittel",
			"distance_in": "Entfernung in:",
			"clear": "löschen",
			"modal_message": "Geben Sie jede Strecke in Meilen an, die Sie zu oder von diesem Gebäude benötigen.",
			"modal_message_si": "Geben Sie jede Strecke in Kilometer an, die Sie zu oder von diesem Gebäude benötigen.",
			"neutral": "Weder zufrieden noch unzufrieden",
			"satisfaction_none": "Weder zufrieden noch unzufrieden",
			"satisfied": "Zufrieden",
			"very_satisfied": "Sehr zufrieden",
			"unsatisfied": "Nicht zufrieden",
			"very_unsatisfied": "Sehr Unzufriedigen",
			"about_arc": "Über Arc",
			"arc_policy": "Datenschutzrichtlinie",
			"thank_you_message":"Vielen Dank für Ihre Teilnahme an unserer Umfrage! Ihre Antworten:",
			"transportation":"Transport",
			"enhance_message": "Bedingungen, die Ihre Zufriedenheit steigern",
			"reduce_message": "Bedingungen, die Ihre Zufriedenheit reduzieren",
			"lobby_page_thankyou": "Vielen Dank für Ihre Teilnahme an unserer Umfrage!",
			"start_survey": "Eine neue Umfrage starten",
			"high_mileage": "Weite Entfernung",
			"slide_to_select": "Schieben Sie zu Ihrer Auswahl",
			"ignore": "Ignorieren",
			"occupant_type_question": "Welcher Gebäudenutzertyp sind Sie?",
			"occupant_type": "Wählen Sie einen Gebäudenutzertyp",
			"regular": "Regelmäßiger Nuter",
			"visitor": "Besucher",
			"submit_error": "Bitte beantworten Sie alle Fragen",
			"next": "Nächste",
			"skip": "Überspringen",
			"previous": "Vorherige",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "Senden Sie eine oder beide der folgenden Angaben",
			"Number of days in a week, you use this route": "Anzahl der Tage in einer Woche, an denen Sie diese Route verwenden",
			"day": "Tag",
			"visitorRouteError": "Besucher können nur 1 Route hinzufügen."
		},
		"turkish":{
			"survey": "Anketi",
			"transport":"Ulaşım",
			"typical_day": "Bu binaya genellikle nasıl gidersiniz? Seyahat yöntemleriniz haftanın gününe göre değişiklik gösteriyorsa (uzaktan çalışma dahil), her gün için bir rota girin. Ziyaretçiyseniz bu binaya nasıl gittiğinize ilişkin bir rota girin.",
			"typical_day_v1": "Tipik bir günde bu binaya nasıl gelip gidiyorsunuz? \"Bir gün, tek yön\" için giriş yapınız.",
			"one_day": "Eğer ulaşım yönteminiz haftanın günü ya da seyahat ettiğiniz yöne göre değişiyorsa, birden çok rota giriniz",
			"route": "Rota",
			"select_travel": "Seyahat yöntemini seçiniz",
			"mandatory_field_error": "Bu cevaplanması zorunlu bir sorudur.",
			"blank_satisfaction_error": "Cevaplanması zorunlu soru. Seçim için kayar cetveli kullanınız.",
			"walk_bike_telecommute": "Yürüme, Bisiklet, Uzaktan çalışma",
			"walk": "Yürüme",
			"bike": "Bisiklet veya elektrikli scooter",
			"telecommute": "Uzaktan çalışma",
			"bus": "Otobüs",
			"light_rail": "Hafif Raylı Sistem",
			"heavy_rail": "Metro",
			"motorcycle": "Motorsiklet/scooter",
			"car": "Özel araç - tek kişi",
			"car23": "Araç paylaşımı 2-3 kişi/Vanpool",
			"cars4": "Alternatif yakıtlı araç",
			"kilometers": "Kilometre",
			"miles": "Mil",
			"add_route": "Başka bir rota ekle",
			"remove_route": "Rotayı sil",
			"satisfaction": "Tatmin",
			"how_satisfied": "1. Binadaki ortam koşullarının sizi ne kadar memnun ettiğini belirtmek için kayar cetveli kullanınız.",
			"how_satisfied_v1": "Binadaki ortam koşullarının sizi ne kadar memnun ettiğini belirtmek için kayar cetveli kullanınız.",
			"extremely_unsatisfied":"Son derece rahatsız edici",
			"extremely_satisfied": "Son derece tatminkar",
			"name": "İsim (isteğe bağlı)",
			"submit": "Teslim et",
			"compliment_message": "2. Bunu öğrendimize sevindik. Lütfen memnuniyetinizi önemli ölçüde artıran aşağıdaki seçenekleri seçin:",
			"compliment_message_v1": "Bunu öğrendimize sevindik. Lütfen memnuniyetinizi önemli ölçüde artıran aşağıdaki seçenekleri seçin:",
			"sorry_message": "2. Bunu duyduğumuza üzüldük. Lütfen memnuniyetinizi önemli ölçüde azaltan aşağıdaki seçenekleri seçin:",
			"sorry_message_v1": "Bunu duyduğumuza üzüldük. Lütfen memnuniyetinizi önemli ölçüde azaltan aşağıdaki seçenekleri seçin:",
			"dirty": "Kirli",
			"drafty": "Esintili",
			"bright": "Parlak",
			"views": "Dışarıya bakış",
			"views_satisfied": "Dışarıya bakış",
			"sound": "Ses",
			"sound_satisfied": "Ses",
			"humid": "Nemli",
			"smelly": "Kötü kokulu",
			"stuffy": "Havasız",
			"loud": "Akustik",
			"loud_v2": "Gürültülü",
			"hot": "Sıcak",
			"cold": "Soğuk",
			"dark": "Karanlık",
			"glare": "Parlama var",
			"privacy": "Mahremiyet",
			"privacy_satisfied": "Mahremiyet",
			"thermal_comfort": "Isıl (Termal) Konfor",
			"air_quality": "Hava kalitesi",
			"cleanliness": "Temizlik",
			"light": "Aydınlık/Işık",
			"daylight": "Günışığı",
			"location": "Konum",
			"comments": "Yorumlar (İsteğe bağlı)",
			"other_complaints": "Diğer Şikayetler",
			"other_location": "Konum - ör. Lobi, 2nci kat doğu cephe, 50. kat - Oda no:500",
			"submit_text": "Gönderiliyor",
			"ok": "OK",
			"error_sending_survey": "Anketi teslim edebilmek için ulaşım bilgilerini doldurunuz",
			"thankyou_message": "Zamanınızı ayırdığınız için teşekkür ederiz.",
			"reading_error_survey": "",
			"acceptnclose": "KABUL ET ve KAPAT",
			"cookies_msg_one": "Bu websitesi en iyi kullanıcı deneyimini sağlamak için çerezler kullanmaktadır.",
			"cookies_msg_two": "Onay vermek ve web sitemizi kullanmaya devam edebilmek için lütfen \"KABUL ET ve KAPAT\" butonuna tıklayınız.  Daha fazla bilgi için lütfen gözden geçiriniz:",
			"cookies_msg_three": "Çerez bilgilendirmesi",
			"save": "KAYDET",
			"cancel": "İPTAL",
			"travel_method": "Ulaşım yöntemi",
			"distance_in": "Gelinen mesafe",
			"clear": "Temiz",
			"modal_message": "Bu binaya ulaşmak için kullandığınız tüm yolları mil cinsinden belirtiniz",
			"modal_message_si": "Bu binaya ulaşmak için kullandığınız tüm yolları kilometre cinsinden belirtiniz",
			"neutral": "Ne tatminkar, ne de rahatsız edici",
			"satisfaction_none": "Ne tatminkar, ne de rahatsız edici",
			"satisfied": "Tatminkar",
			"very_satisfied": "Çok tatminkar",
			"unsatisfied": "Rahatsız edici",
			"very_unsatisfied": "Çok rahatsız edici",
			"about_arc": "Arc Hakkında",
			"arc_policy": "Gizlilik Politikası",
			"thank_you_message":"Anketimizi doldurduğunuz için teşekkür ederiz. Cevaplarınız:",
			"transportation":"Ulaşım",
			"enhance_message": "Tatmininizi arttıran seçenekler",
			"reduce_message": "Rahatsız edici bulmanıza neden olan seçenekler",
			"lobby_page_thankyou": "Anketimizi doldurduğunuz için teşekkür ederiz.",
			"start_survey": "Yeni bir ankete başlayın",
			"high_mileage": "Fazla mesafe",
			"slide_to_select": "Seçiminize doğru kaydırınız",
			"ignore": "Gözardı et",
			"occupant_type_question": "Hangi tip bina sakinisiniz?",
			"occupant_type": "Bina Sakini Tipi",
			"regular": "Bina sakini / çalışanı",
			"visitor": "Ziyaretçi",
			"submit_error": "Lütfen tüm soruları cevaplayınız",
			"next": "Sonraki",
			"skip": "Atlamak",
			"previous": "Öncesi",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"transportation_label": "UIasim",
			"human_experience": "Kisisel Bina Deneyimi",
			"submit_both": "Aşağıdakilerden birini veya her ikisini gönderin",
			"Number of days in a week, you use this route": "Haftada bu rotayı kullandığınız gün sayısı",
			"day": "gün",
			"visitorRouteError": "Ziyaretçiler yalnızca 1 rota ekleyebilir."
		},
		"japanese": {
			"about_arc": "アークについて",
			"acceptnclose": "同意して閉じる",
			"add_route": "別のルートを追加する",
			"air_quality": "空気質",
			"arc_policy": "個人情報保護方針",
			"bike": "自転車または電動スクーター",
			"blank_satisfaction_error": "必須の質問です。スライダーを使用して選択します。",
			"bright": "まぶしい",
			"bus": "バス",
			"cancel": "キャンセル",
			"car": "車(単独)",
			"car23": "相乗り2～3名/バンプール",
			"cars4": "代替燃料車(ガソリン・軽油以外)",
			"certified": "CERTIFIED",
			"cleanliness": "清潔さ",
			"clear": "全消去",
			"cold": "寒い",
			"comments": "コメント（任意）",
			"compliment_message": "2. 満足頂き光栄です。以下、満足度を高めた要因を選択してください。",
			"compliment_message_v1": "満足頂き光栄です。以下、満足度を高めた要因を選択してください。",
			"cookies_msg_one": "このWebサイトは、Cookieを収集、使用し、最高のユーザー経験を提供します。",
			"cookies_msg_three": "クッキーステートメント",
			"cookies_msg_two": "「同意して閉じる」ボタンをクリックして同意を確認し、引き続き当社のWebサイトを使用してください。詳細についてはクッキーステートメントを参照してください。",
			"dark": "暗い",
			"daylight": "日光",
			"dirty": "汚い",
			"distance_in": "距離の単位",
			"drafty": "すきま風",
			"energy": "ENERGY",
			"enhance_message": "あなたの満足度を高めるオプション",
			"error_sending_survey": "アンケートを送信できるようにするには、交通データを入力してください",
			"extremely_satisfied": "極めて満足",
			"extremely_unsatisfied": "極めて不満",
			"glare": "まぶしい",
			"gold": "GOLD",
			"heavy_rail": "電車",
			"high_mileage": "低燃費",
			"hot": "暑い",
			"how_satisfied": "1. スライダーを用いて建物利用満足度を示してください。",
			"how_satisfied_v1": "スライダーを用いて建物利用満足度を示してください。",
			"human_experience": "建物利用者満足度",
			"humid": "湿気",
			"ignore": "無視する",
			"kilometers": "キロメートル",
			"last_cert": "Last certification date",
			"level_cert": "Certification Level",
			"light": "明るさ",
			"light_rail": "ライトレール（路面電車、トロリーバスなど）",
			"lobby_page_thankyou": "アンケートにご協力いただきありがとうございます。",
			"location": "ロケーション",
			"loud": "音響",
			"loud_v2": "騒がしい",
			"mandatory_field_error": "これは必須の質問です",
			"miles": "マイル",
			"modal_message": "この建物に来るために移動した距離を記入してください（移動手段ごと）",
			"modal_message_si": "この建物に来るために移動した距離を記入してください（移動手段ごと）",
			"motorcycle": "オートバイ",
			"name": "名前（任意）",
			"neutral": "満足でも不満でもない",
			"occupant_type": "居住者のタイプを選択",
			"occupant_type_question": "あなたはどのタイプの居住者ですか？",
			"ok": "OK",
			"one_day": "交通手段が曜日や方向によって異なる場合は、複数のルートを入力してください",
			"other_complaints": "その他の苦情",
			"other_location": "場所-例：ロビー、東棟2階、50階-スイート500",
			"platinum": "PLATINUM",
			"privacy": "プライバシー",
			"privacy_satisfied": "プライバシー",
			"reading_error_survey": "",
			"reduce_message": "あなたの満足度を下げるオプション",
			"regular": "一般の利用者",
			"remove_route": "ルートを削除する",
			"route": "ルート",
			"satisfaction": "満足度",
			"satisfaction_none": "満足でも不満でもない",
			"satisfied": "満足",
			"save": "保存",
			"select_travel": "交通手段を選択する",
			"silver": "SILVER",
			"slide_to_select": "該当する満足度にスライドさせてください",
			"smelly": "臭い",
			"sorry_message": "2. ご不便をおかけして申し訳ございません。以下、満足度を下げた要因を選択してください。",
			"sorry_message_v1": "ご不便をおかけして申し訳ございません。満足度を大幅に低下させたオプションを以下から選択してください。",
			"sound": "音",
			"sound_satisfied": "音",
			"start_survey": "新しい調査を開始する",
			"stuffy": "息苦しい",
			"submit": "提出",
			"submit_error": "すべての質問に答えてください",
			"submit_text": "送信中...",
			"survey": "調査",
			"telecommute": "交通手段を利用しない(在宅勤務)",
			"thank_you_message": "アンケートにご協力いただきありがとうございます。あなたの回答",
			"thankyou_message": "お時間をいただきありがとうございます。",
			"thermal_comfort": "温度の快適性",
			"transport": "交通",
			"transportation": "交通",
			"transportation_label": "交通",
			"travel_method": "交通手段",
			"typical_day": "あなたはいつもどうやってこの建物に行きますか? 曜日によって移動方法が異なる場合（在宅勤務含む）は、曜日ごとのルートを入力してください。 あなたが訪問者の場合、この建物までの移動ルートを 1 つ入力してください。",
			"typical_day_v1": "通常、この建物への行き帰りはどのようにしていますか？”一日の片道”における移動内容を入力してください。",
			"unsatisfied": "不満",
			"very_satisfied": "非常に満足",
			"very_unsatisfied": "非常に不満",
			"views": "眺望",
			"views_satisfied": "眺望",
			"visitor": "訪問者",
			"walk": "徒歩",
			"walk_bike_telecommute": "徒歩, 自転車, 在宅勤務",
			"waste": "WASTE",
			"water": "WATER",
			"next": "次",
			"skip": "スキップ",
			"previous": "前",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "以下のうちいずれか、または両方を回答して送信ボタンをクリックしてください。",
			"Number of days in a week, you use this route": "週にこのルートを使用する日数",
			"day": "日",
			"visitorRouteError": "訪問者が追加できるルートは 1 つだけです。"
		},
		"korean": {
			"about_arc": "Arc 에관한여",
			"acceptnclose": "수락 및 닫기",
			"add_route": "다른 경로 추가",
			"air_quality": "공기청정도",
			"arc_policy": "개인 정보 정책",
			"bike": "자전거 또는 전기 스쿠터",
			"blank_satisfaction_error": "필수 질문입니다. 슬라이더를 사용하여 선택합니다.",
			"bright": "밝다",
			"bus": "버스",
			"cancel": "취소",
			"car": "차 (1명) ",
			"car23": "카풀 2~3명/밴풀",
			"cars4": "대체연료차",
			"certified": "CERTIFIED",
			"cleanliness": "청결도",
			"clear": "맑은",
			"cold": "덥다",
			"comments": "기타의견 (선택 사항)",
			"compliment_message": "2. 우리는 그것을 듣고 기쁩니다. 만족도를 크게 높이는 옵션을 아래에서 선택하십시오.",
			"compliment_message_v1": "우리는 그것을 듣고 기쁩니다. 만족도를 크게 높이는 옵션을 아래에서 선택하십시오.",
			"cookies_msg_one": "이 웹 사이트는 최상의 사용자 경험을 제공하기 위해 쿠키를 수집하고 사용합니다.",
			"cookies_msg_three": "쿠키 성명",
			"cookies_msg_two": "동의를 확인하고 웹 사이트를 계속 사용하려면 \"동의 및 닫기\"버튼을 클릭하십시오. 자세한 내용은",
			"dark": "조도가 낮다",
			"daylight": "일광",
			"dirty": "지저분함",
			"distance_in": "거리 정보",
			"drafty": "외풍유입",
			"energy": "ENERGY",
			"enhance_message": "만족도를 높이는 옵션",
			"error_sending_survey": "설문 조사를 제출하려면 교통 정보를 입력하세요.",
			"extremely_satisfied": "아주 만족스러운",
			"extremely_unsatisfied": "매우 불만족",
			"glare": "너무 환하다",
			"gold": "GOLD",
			"heavy_rail": "지하철",
			"high_mileage": "높은 마일리지",
			"hot": "뜨거운",
			"how_satisfied": "1. 슬라이더를 사용하여이 건물의 환경에 얼마나 만족하는지 나타냅니다.",
			"how_satisfied_v1": "슬라이더를 사용하여이 건물의 환경에 얼마나 만족하는지 나타냅니다.",
			"human_experience": "HUMAN EXPERIENCE",
			"humid": "습기",
			"ignore": "무시",
			"kilometers": "킬로미터",
			"last_cert": "Last certification date",
			"level_cert": "Certification Level",
			"light": "빛",
			"light_rail": "경전철",
			"lobby_page_thankyou": "설문 조사에 응 해주셔서 감사합니다!",
			"location": "위치",
			"loud": "음향시설",
			"loud_v2": "소음이 크다",
			"mandatory_field_error": "필수 질문입니다.",
			"miles": "마일",
			"modal_message": "이 건물까지 이동하는 모든 마일을 포함하세요.",
			"modal_message_si": "이 건물까지 이동하는 거리를 포함하세요. (Km)",
			"motorcycle": "오토바이",
			"name": "이름 (선택 사항)",
			"neutral": "만족하지도 불만족스럽지도 않다",
			"occupant_type": "입주자 유형 선택",
			"occupant_type_question": "당신은 어떤 유형의 임차인입니까?",
			"ok": "확인",
			"one_day": "요일이나 여행 방향에 따라 교통 수단이 다른 경우 여러 경로를 입력하세요.",
			"other_complaints": "기타 불만",
			"other_location": "위치-예. 로비, 2 층 동쪽, 50 층-스위트 500",
			"platinum": "PLATINUM",
			"privacy": "개인 프라이버시",
			"privacy_satisfied": "개인 프라이버시",
			"reading_error_survey": "",
			"reduce_message": "만족도를 낮추는 옵션",
			"regular": "일반 거주자",
			"remove_route": "경로 제거",
			"route": "경로",
			"satisfaction": "만족감",
			"satisfaction_none": "만족하지도 불만족스럽지도 않다",
			"satisfied": "만족하다",
			"save": "저장",
			"select_travel": "출근 방법 선택",
			"silver": "SILVER",
			"slide_to_select": "선택 항목으로 슬라이드",
			"smelly": "냄새난다",
			"sorry_message": "2. 유감입니다. 만족도를 크게 낮추는 옵션을 아래에서 선택하십시오.",
			"sorry_message_v1": "유감입니다. 만족도를 크게 낮추는 옵션을 아래에서 선택하십시오.",
			"sound": "소리",
			"sound_satisfied": "소리",
			"start_survey": "새 설문 조사 시작",
			"stuffy": "답답하다",
			"submit": "제출",
			"submit_error": "모든 질문에 답 해주세요",
			"submit_text": "제출 중 ...",
			"survey": "조사",
			"telecommute": "재택근무",
			"thank_you_message": "설문 조사에 응 해주셔서 감사합니다! 귀하의 응답",
			"thankyou_message": "시간을 내 주셔서 감사합니다.",
			"thermal_comfort": "쾌적환경",
			"transport": "교통편",
			"transportation": "교통",
			"transportation_label": "교통",
			"travel_method": "여행 방법",
			"typical_day": "이 건물에는 보통 어떻게 가나요? 요일별로 이동방법이 다른 경우(재택근무 포함), 요일별 경로를 입력하세요. 방문자인 경우 이 건물까지 어떻게 이동했는지에 대한 경로를 하나 입력하세요.",
			"typical_day_v1": "보통 날에는 이 건물로 어떻게 오가나요? \"일일, 편도\" 통근 거리 입력:",
			"unsatisfied": "불만족스러움",
			"very_satisfied": "매우 만족하다",
			"very_unsatisfied": "매우 불만족스러움",
			"views": "야외 전망",
			"views_satisfied": "야외 전망",
			"visitor": "방문객",
			"walk": "걷는다",
			"walk_bike_telecommute": "걷는다, 자전거, 재택근무",
			"waste": "WASTE",
			"water": "WATER",
			"next": "다음",
			"skip": "건너뛰다",
			"previous": "이전의",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "다음 중 하나 또는 둘 다를 제출",
			"Number of days in a week, you use this route": "일주일 중 이 경로를 사용한 일수",
			"day": "낮",
			"visitorRouteError": "방문자는 경로를 1개만 추가할 수 있습니다."
		},
		"greek":
		{
			survey: 'Έρευνα',
			transport: 'Μετακίνηση',
			typical_day:
			'Πώς πηγαίνετε συνήθως σε αυτό το κτίριο; Εάν οι μέθοδοι ταξιδιού σας διαφέρουν ανάλογα με την ημέρα της εβδομάδας (συμπεριλαμβανομένης της τηλεργασίας), εισαγάγετε μια διαδρομή για κάθε ημέρα. Εάν είστε επισκέπτης, εισαγάγετε μία διαδρομή για το πώς ταξιδέψατε σε αυτό το κτίριο.',
			typical_day_v1:
			'Σε μια τυπική μέρα, πώς πηγαίνετε από και προς αυτό το κτίριο; Εισάγετε αποτελέσματα για "μια μέρα, μια διαδρομή',
			one_day:
			'Εάν οι τρόποι μεταφοράς σας διαφέρουν ανάλογα με την ημέρα της εβδομάδας ή την κατεύθυνση ταξιδιού, εισάγετε πολλές/διάφορες διαδρομές',
			route: 'Διαδρομή',
			select_travel: 'Επιλέξτε Μέθοδος ταξιδιού',
			mandatory_field_error: 'Απαιτείται απάντηση στην ερώτηση ',
			blank_satisfaction_error:
			'Απαιτείται απάντηση στην ερώτηση. Χρησιμοποιήστε το ρυθμιστικό για να επιλέξετε.',
			walk_bike_telecommute: 'περπάτημα, ποδήλατο, τηλεργασία',
			walk: 'περπάτημα',
			bike: 'ποδήλατο',
			telecommute: 'τηλεργασία',
			bus: 'λεωφορείο',
			light_rail: 'ελαφρύς σιδηρόδρομος (τρόλευ, τραμ)',
			heavy_rail: 'μέσα ταχείας μεταφοράς (μετρό, ηλεκτρικός σιδηρόδρομος)',
			motorcycle: 'μοτοσυκλέτα/σκούτερ (μικρό μηχανάκι)',
			car: 'αυτοκίνητο (1 άτομο)',
			car23: 'Carpool 2-3 ατόμων/Vanpool',
			cars4: 'αυτοκίνητο εναλλακτικού καυσίμου',
			kilometers: 'Χιλιόμετρο',
			miles: 'Μίλι',
			add_route: 'Προσθέστε κι άλλη διαδρομή',
			remove_route: 'Κατάργηση διαδρομής',
			satisfaction: 'Ικανοποίηση',
			how_satisfied:
			'1. Χρησιμοποιήστε το ρυθμιστικό για να υποδείξετε πόσο ικανοποιημένος/η είστε με το περιβάλλον σε αυτό το κτίριο',
			how_satisfied_v1:
			'Χρησιμοποιήστε το ρυθμιστικό για να υποδείξετε πόσο ικανοποιημένος/η είστε με το περιβάλλον σε αυτό το κτίριο',
			extremely_unsatisfied: 'Εξαιρετικά δυσαρεστημένος/η',
			extremely_satisfied: 'Εξαιρετικά ικανοποιημένος/η',
			name: 'όνομα (προαιρετικό)',
			submit: 'υποβολή',
			compliment_message:
			'2. Χαιρόμαστε που το ακούμε. Επιλέξτε τις παρακάτω επιλογές που αυξάνουν σημαντικά την ικανοποίησή σας:',
			compliment_message_v1:
			'Χαιρόμαστε που το ακούμε. Επιλέξτε τις παρακάτω επιλογές που αυξάνουν σημαντικά την ικανοποίησή σας:',
			sorry_message:
			'2. Λυπούμαστε που το ακούμε. Παρακαλούμε επιλέξτε παρακάτω τους παράγοντες που μειώνουν σημαντικά την ικανοποίησή σας:',
			sorry_message_v1:
			'Λυπούμαστε που το ακούμε. Παρακαλούμε επιλέξτε παρακάτω τους παράγοντες που μειώνουν σημαντικά την ικανοποίησή σας:',
			dirty: 'βρώμικο',
			drafty: 'Σχεδιασμός',
			bright: 'Φωτεινότητα',
			views: 'Θέα σε εξωτερικούς χώρους',
			views_satisfied: 'Θέα σε εξωτερικούς χώρους',
			sound: 'Ήχος',
			sound_satisfied: 'Ήχος',
			humid: 'Υγρασία',
			smelly: 'δυσάρεστη μυρωδιά',
			stuffy: 'αποπνικτικό',
			loud: 'Ακουστική χώρου',
			loud_v2: 'θορυβώδες',
			hot: 'ζεστό',
			cold: 'κρύο',
			dark: 'σκοτεινό',
			glare: 'εκτυφλωτικό',
			privacy: 'ιδιωτικότητα',
			privacy_satisfied: 'ιδιωτικότητα',
			thermal_comfort: 'Θερμική άνεση',
			air_quality: 'Ποιότητα αέρα',
			cleanliness: 'Καθαριότητα',
			light: 'Φως',
			daylight: 'Φως ημέρας',
			location: 'τοποθεσία',
			comments: 'Σχόλια (Προαιρετικά)',
			other_complaints: 'άλλα παράπονα',
			other_location:
			'Τοποθεσία - π.χ. χώρος υποδοχής, 2ος όροφος Ανατολικός, 50ος Όροφος - Σουίτα 500',
			submit_text: 'Υποβολή...',
			ok: 'Εντάξει',
			error_sending_survey:
			'Παρακαλούμε συμπληρώστε τα στοιχεία μεταφοράς για να μπορέσετε να υποβάλετε την έρευνα',
			thankyou_message: 'Thank you for taking the time.',
			reading_error_survey: '',
			acceptnclose: 'ΑΠΟΔΟΧΗ ΚΑΙ ΚΛΕΙΣΙΜΟ',
			cookies_msg_one:
			'Αυτός ο ιστότοπος συλλέγει και χρησιμοποιεί cookies για να διασφαλίσει ότι θα έχετε την καλύτερη εμπειρία χρήστη.',
			cookies_msg_two:
			'Κάντε κλικ στο κουμπί "Αποδοχή και Κλείσιμο" για να επιβεβαιώσετε τη συγκατάθεσή σας και να συνεχίσετε να χρησιμοποιείτε τον ιστότοπό μας. Για περισσότερες πληροφορίες, δείτε το',
			cookies_msg_three: 'Δήλωση cookies',
			save: 'ΑΠΟΘΗΚΕΥΣΗ',
			cancel: 'ΑΚΥΡΩΣΗ',
			travel_method: 'Μέθοδος ταξιδιού',
			distance_in: 'Απόσταση σε:',
			clear: 'Καθαρισμός',
			modal_message: 'Συμπεριλάβετε τυχόν μίλια που διανύετε για να φτάσετε σε αυτό το κτίριο',
			modal_message_si: 'Συμπεριλάβετε τυχόν χιλιόμετρα που διανύετε για να φτάσετε σε αυτό το κτίριο',
			neutral: 'Ούτε ικανοποιημένος/η ούτε δυσαρεστημένος',
			satisfaction_none: 'Ούτε ικανοποιημένος/η ούτε δυσαρεστημένος',
			satisfied: 'Ικανοποιημένος/η',
			very_satisfied: 'Πολύ ικανοποιημένος/η',
			unsatisfied: 'Δυσαρεστημένος/η',
			very_unsatisfied: 'Πολύ δυσαρεστημένος/η',
			about_arc: 'Σχετικά με το Arc',
			arc_policy: 'Πολιτική Απορρήτου',
			thank_you_message: 'Ευχαριστούμε που συμμετείχατε στην έρευνα μας! Οι απαντήσεις σας:',
			transportation: 'Transportation',
			enhance_message: 'Επιλογές που ενισχύουν την ικανοποίησή σας',
			reduce_message: 'Επιλογές που μειώνουν την ικανοποίησή σας',
			lobby_page_thankyou: 'Σας ευχαριστούμε που λάβατε μέρος στην έρευνά μας!',
			start_survey: 'Ξεκινήστε μια νέα έρευνα',
			high_mileage: 'Υψηλή χιλιομετρική απόσταση',
			slide_to_select: 'Σύρετε για την επιλογή σας',
			ignore: 'Αγνοήστε',
			occupant_type_question: 'Τι συχνότητα χρήσης κάνετε;',
			occupant_type: 'Επιλέξτε τύπο χρήσης',
			regular: 'Τακτικός \'\'κάτοικος/χρήστης\'\'',
			visitor: 'Επισκέπτης',
			submit_error: 'Παρακαλώ απαντήστε σε όλες τις ερωτήσεις',
			energy: 'ENERGY',
			water: 'WATER',
			waste: 'WASTE',
			transportation_label: 'TRANSPORTATION',
			human_experience: 'HUMAN EXPERIENCE',
			silver: 'SILVER',
			gold: 'GOLD',
			platinum: 'PLATINUM',
			certified: 'CERTIFIED',
			last_cert: 'Last certification date',
			level_cert: 'Certification Level',
			"next": "Επόμενο",
			"skip": "Παραλείπω",
			"previous": "Προηγούμενος",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "Υποβάλετε ένα ή και τα δύο από τα παρακάτω",
			"Number of days in a week, you use this route": "Αριθμός ημερών σε μια εβδομάδα, χρησιμοποιείτε αυτήν τη διαδρομή",
			"day": "낮",
			"visitorRouteError": "Οι επισκέπτες μπορούν να προσθέσουν μόνο 1 διαδρομή."
		},
		"urdu":
		{
			survey: 'سروے',
			transport: 'ٹرانسپورٹ',
			typical_day:
			'آپ عام طور پر اس عمارت تک کیسے پہنچتے ہیں؟ اگر آپ کے سفر کے طریقے ہفتے کے دن کے لحاظ سے مختلف ہوتے ہیں (بشمول ٹیلی کام،) ہر دن کے لیے ایک راستہ درج کریں۔ اگر آپ وزیٹر ہیں، تو ایک راستہ درج کریں کہ آپ نے اس عمارت تک کیسے سفر کیا۔',
			typical_day_v1:
			'ایک عام دن پر، آپ اس عمارت تک اور وہاں سے کیسے جاتے ہیں؟ "ایک دن، ایک راستہ" کے نتائج درج کریں۔',
			one_day:
			'اگر آپ کے نقل و حمل کے طریقے ہفتے کے دن یا سفر کی سمت کے لحاظ سے مختلف ہوتے ہیں تو متعدد راستے داخل کریں۔',
			route: 'راستہ ',
			select_travel: 'سفر کا طریقہ منتخب کریں۔',
			mandatory_field_error: 'یہ ایک مطلوبہ سوال ہے۔',
			blank_satisfaction_error: 'مطلوبہ سوال۔ انتخاب کرنے کے لیے سلائیڈر کا استعمال کریں۔',
			walk_bike_telecommute: 'چلنا, موٹر سائیکل, ٹیلی کام',
			walk: 'چلنا',
			bike: 'موٹر سائیکل',
			telecommute: 'ٹیلی کام',
			bus: 'بس',
			light_rail: 'ہلکی ریل (ٹرالی، ٹرام، اسٹریٹ کار)',
			heavy_rail: 'ریپڈ ٹرانزٹ (سب وے، میٹرو)',
			motorcycle: 'موٹر سائیکل/سکوٹر',
			car: 'کار سولو',
			car23: 'کارپول 2-3 افراد/وان پول',
			cars4: 'کار متبادل ایندھن',
			kilometers: 'کلومیٹر',
			miles: 'میل',
			add_route: 'دوسرا راستہ شامل کریں',
			remove_route: 'راستے کو ہٹا دیں۔',
			satisfaction: 'اطمینان',
			how_satisfied:
			'1. یہ بتانے کے لیے سلائیڈر استعمال کریں کہ آپ اس عمارت کے ماحول سے کتنے مطمئن ہیں۔',
			how_satisfied_v1:
			'یہ بتانے کے لیے سلائیڈر استعمال کریں کہ آپ اس عمارت کے ماحول سے کتنے مطمئن ہیں۔',
			extremely_unsatisfied: 'انتہائی غیر مطمئن',
			extremely_satisfied: 'انتہائی مطمئن',
			name: 'نام (اختیاری)',
			submit: 'جمع کرائیں',
			compliment_message:
			'2. ہمیں یہ سن کر خوشی ہوئی۔ براہ کرم ذیل میں ان اختیارات کو منتخب کریں جو آپ کے اطمینان کو نمایاں طور پر بڑھاتے ہیں:',
			compliment_message_v1:
			'ہمیں یہ سن کر خوشی ہوئی۔ براہ کرم ذیل میں ان اختیارات کو منتخب کریں جو آپ کے اطمینان کو نمایاں طور پر بڑھاتے ہیں:',
			sorry_message:
			'2. ہمیں یہ سن کر افسوس ہوا۔ براہ کرم نیچے دیے گئے اختیارات کو منتخب کریں جو آپ کے اطمینان کو نمایاں طور پر کم کرتے ہیں۔',
			sorry_message_v1:
			'ہمیں یہ سن کر افسوس ہوا۔ براہ کرم نیچے دیے گئے اختیارات کو منتخب کریں جو آپ کے اطمینان کو نمایاں طور پر کم کرتے ہیں۔',
			dirty: 'گندا',
			drafty: 'ڈرافٹ',
			bright: 'روشن',
			views: 'باہر کے نظارے۔',
			views_satisfied: 'باہر کے نظارے۔',
			sound: 'آواز',
			sound_satisfied: 'آواز',
			humid: 'مرطوب',
			smelly: 'بدبودار',
			stuffy: 'بھرا ہوا',
			loud: 'صوتیات',
			loud_v2: 'بلند آواز',
			hot: 'گرم',
			cold: 'سردی',
			dark: 'اندھیرا',
			glare: 'چکاچوند',
			privacy: 'رازداری',
			privacy_satisfied: 'رازداری',
			thermal_comfort: 'تھرمل آرام',
			air_quality: 'ہوا کا معیار',
			cleanliness: 'صفائی',
			light: 'روشنی',
			daylight: 'دن کی روشنی',
			location: 'مقام',
			comments: 'تبصرے (اختیاری)',
			other_complaints: 'دیگر شکایات',
			other_location:
			'مقام - مثال کے طور پر لابی، دوسری منزل ایسٹ، 50ویں منزل - سویٹ 500',
			submit_text: 'جمع کر رہا ہے...',
			ok: 'ٹھیک ہے',
			error_sending_survey:
			'سروے جمع کرانے کے قابل ہونے کے لیے براہ کرم نقل و حمل کا ڈیٹا پُر کریں۔',
			thankyou_message: 'وقت نکالنے کے لیے آپ کا شکریہ۔"',
			reading_error_survey: '',
			acceptnclose: 'قبول کریں اور بند کریں۔',
			cookies_msg_one:
			'یہ ویب سائٹ کوکیز کو جمع کرتی ہے اور استعمال کرتی ہے اس بات کو یقینی بنانے کے لیے کہ آپ کو بہترین صارف کا تجربہ حاصل ہو۔',
			cookies_msg_two:
			'Please click on "Accept and Close" button to affirm your consent and continue to use our website. For more information, please view our',
			cookies_msg_three: 'کوکیز کا بیان',
			save: 'محفوظ کریں۔',
			cancel: 'منسوخ کریں۔',
			travel_method: 'سفر کا طریقہ',
			distance_in: 'فاصلہ اندر',
			clear: 'صاف',
			modal_message: 'اس عمارت تک پہنچنے کے لیے آپ جو بھی میل سفر کرتے ہیں اسے شامل کریں۔',
			modal_message_si: 'اس عمارت تک پہنچنے کے لیے آپ جس کلومیٹر کا سفر کرتے ہیں اسے شامل کریں۔',
			neutral: 'نہ مطمئن نہ غیر مطمئن',
			satisfaction_none: 'نہ مطمئن نہ غیر مطمئن',
			satisfied: 'مطمئن',
			very_satisfied: 'بہت مطمئن',
			unsatisfied: 'غیر مطمئن',
			very_unsatisfied: 'بہت غیر مطمئن',
			about_arc: 'آرک کے بارے میں',
			arc_policy: 'رازداری کی پالیسی',
			thank_you_message: 'ہمارے سروے میں حصہ لینے کے لیے آپ کا شکریہ! آپ کے جوابات',
			transportation: 'نقل و حمل',
			enhance_message: 'اختیارات جو آپ کے اطمینان کو بڑھاتے ہیں۔',
			reduce_message: 'وہ اختیارات جو آپ کے اطمینان کو کم کرتے ہیں۔',
			lobby_page_thankyou: 'ہمارے سروے میں حصہ لینے کے لیے آپ کا شکریہ!',
			start_survey: 'ایک نیا سروے شروع کریں۔',
			high_mileage: 'ہائی مائلیج',
			slide_to_select: 'اپنے انتخاب پر سلائیڈ کریں۔',
			ignore: 'نظر انداز کرنا',
			occupant_type_question: 'آپ کس قسم کے مکین ہیں؟',
			occupant_type: 'مقیم کی قسم منتخب کریں۔',
			regular: 'باقاعدہ مقیم',
			visitor: 'وزیٹر',
			submit_error: 'براہ کرم تمام سوالات کے جوابات دیں۔',
			energy: 'ENERGY',
			water: 'WATER',
			waste: 'WASTE',
			transportation_label: 'TRANSPORTATION',
			human_experience: 'HUMAN EXPERIENCE',
			silver: 'SILVER',
			gold: 'GOLD',
			platinum: 'PLATINUM',
			certified: 'CERTIFIED',
			last_cert: 'Last certification date',
			level_cert: 'Certification Level',
			"next": "اگلے",
			"skip": "چھوڑ دو",
			"previous": "پچھلا",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "درج ذیل میں سے ایک یا دونوں جمع کروائیں۔",
			"Number of days in a week, you use this route": "ایک ہفتے میں دنوں کی تعداد، آپ یہ راستہ استعمال کرتے ہیں۔",
			"day": "دن",
			"visitorRouteError": "زائرین صرف 1 راستہ شامل کر سکتے ہیں۔"
		},
		"hebrew":
		{
			survey: 'סקר',
			transport: 'תחבורה',
			typical_day:
			'איך מגיעים בדרך כלל לבניין הזה? אם שיטות הנסיעה שלך משתנות לפי יום בשבוע (כולל עבודה מרחוק), הזן מסלול לכל יום. אם אתה מבקר, הזן מסלול אחד לדרך שבה נסעת לבניין הזה.',
			typical_day_v1:
			'ביום סטנדרטי, איך את\\ה מגיע אל ומהבניין? נא התייחס ליום אחד ככיוון אחד.',
			one_day:
			'במידה ואמצעי התחבורה בו אתה משתמש משתנה בהתאם ליום בשבוע/כיוון הנסיעה, הכנס את כל האופציות בהן אתה משתמש',
			route: 'מסלול',
			select_travel: 'בחר סוג אמצעי תחבורה',
			mandatory_field_error: 'אנא ענה על שאלה זו',
			blank_satisfaction_error: 'השתמש בסקלה כדי להגדיר את בחירתך',
			walk_bike_telecommute: 'הליכה, אופניים, עבודה מהבית',
			walk: 'הליכה',
			bike: 'אופניים/קורקינט',
			telecommute: 'עבודה מהבית',
			bus: 'אוטובוס',
			light_rail: 'רכבת קלה',
			heavy_rail: 'רכבת כבדה (רכבת ישראל)',
			motorcycle: 'קטנוע/אופנוע',
			car: 'נהיגה לבד ברכב פרטי',
			car23: 'Carpool 2-3 אנשים/Vanpool',
			cars4: 'רכב חשמלי',
			kilometers: 'ק"מ',
			miles: 'מייל',
			add_route: 'הוסף מסלול ',
			remove_route: 'הורד מסלול',
			satisfaction: 'מידת שביעות רצונך',
			how_satisfied:
			'1. השתמש בסקלה הנתונה והגדר את מידת שביעותך מסביבת בניין זה.',
			how_satisfied_v1: 'השתמש בסקלה הנתונה והגדר את מידת שביעותך מסביבת בניין זה.',
			extremely_unsatisfied: 'לא מרוצה במידה רבה מאוד',
			extremely_satisfied: 'מרוצה במידה רבה מאוד',
			name: 'שם (אופציונלי)',
			submit: 'הגש/שלח',
			compliment_message:
			'2. אנחנו שמחים לשמוע. אנא בחר את מהאופציות מטה את הגורמים העיקריים התורמים לשביעות רצונך',
			compliment_message_v1:
			'אנחנו שמחים לשמוע. אנא בחר את מהאופציות מטה את הגורמים העיקריים התורמים לשביעות רצונך',
			sorry_message:
			'2. אנחנו מצטערים לשמוע. אנא בחר מהאופציות מטה את הגורמים העיקריים אשר גורמים לחוסר שביעות רצונך-',
			sorry_message_v1:
			'אנחנו מצטערים לשמוע. אנא בחר מהאופציות מטה את הגורמים העיקריים אשר גורמים לחוסר שביעות רצונך-',
			dirty: 'לכלוך',
			drafty: 'חדירת אוויר',
			bright: 'בהירות',
			views: 'גישה לנוף/חוץ',
			views_satisfied: 'גישה לנוף/חוץ',
			sound: 'קול',
			sound_satisfied: 'קול',
			humid: 'לחות',
			smelly: 'מסריח',
			stuffy: 'מחניק',
			loud: 'אקוסטיקה',
			loud_v2: 'רועש',
			hot: 'חם',
			cold: 'קר',
			dark: 'חשוך',
			glare: 'סינוור',
			privacy: 'פרטיות',
			privacy_satisfied: 'פרטיות',
			thermal_comfort: 'נוחות תרמית',
			air_quality: 'איכות אוויר',
			cleanliness: 'ניקיון',
			light: 'אור',
			daylight: 'אור יום',
			location: 'מיקום',
			comments: 'הערות (אופציונלי)',
			other_complaints: 'תלונות נוספות',
			other_location: 'מיקום- לובי, קומה שנייה ממערב, הקומה ה-50',
			submit_text: 'הגש/שלח',
			ok: 'בסדר',
			error_sending_survey: 'אנא מלא את המידע הנוגע לתחבורה בכדי שתוכל/י להגיש את הסקר',
			thankyou_message: 'תודה רבה לך על שהשקעת מזמנך!',
			reading_error_survey: null,
			acceptnclose: 'אשר וסגור',
			cookies_msg_one:
			'אתר זה משתמש ב"עוגיות" בכדי לאפשר חוויות משתמש טובה ככל הניתן',
			cookies_msg_two:
			'אנא הקש על הכפתור "אשר וסגור" בכדי לאשר את הסכמתך ולהמשיך להשתמש באתר זה. בשביל מידע נוסף, אנא ראה/י את-',
			cookies_msg_three: 'הצהרת מדיניות "עוגיות"',
			save: 'שמור',
			cancel: 'בטל',
			travel_method: 'אמצעי תחבורה',
			distance_in: 'מרחק ב:',
			clear: 'נקה',
			modal_message: 'הגדר את המרחק (במייל) אשר נידרש בכדי להגיע לבניין זה',
			modal_message_si: 'הגדר את המרחק (בק"מ) אשר נידרש בכדי להגיע לבניין זה',
			neutral: 'אינני מרוצה אך גם אינני לא מרוצה',
			satisfaction_none: 'אינני מרוצה אך גם אינני לא מרוצה',
			satisfied: 'מרוצה',
			very_satisfied: 'מרוצה במידה רבה',
			unsatisfied: 'לא מרוצה',
			very_unsatisfied: 'לא מרוצה במידה רבה',
			about_arc: 'לגבי ARC',
			arc_policy: 'מדיניות פרטיות',
			thank_you_message: 'תודה רבה לך על השתתפותך בסקר! תשבותך היא:',
			transportation: 'תחבורה',
			enhance_message: 'אופציות שתורמות לשביעות רצונך',
			reduce_message: 'אופציות שגורמות לחוסר שביעות רצונך',
			lobby_page_thankyou: 'תודה רבה לך על השתתפותך בסקר! ',
			start_survey: 'התחל סקר חדש',
			high_mileage: 'קילומטראז\' גבוה',
			slide_to_select: 'החלק לבחירתך',
			ignore: 'התעלם',
			occupant_type_question: 'איזה סוג משתמש אתה?',
			occupant_type: 'בחר סוג משתמש',
			regular: 'משתמש רגיל',
			visitor: 'אורח',
			submit_error: 'אנא ענה על כל השאלות',
			energy: 'אנרגיה',
			water: 'מים',
			waste: 'פסולת',
			transportation_label: 'תחבורה',
			human_experience: 'ניסיון אנושי',
			silver: 'כסף',
			gold: 'זהב',
			platinum: 'פלטינום',
			certified: 'מוסמך',
			last_cert: 'תאריך הסמכה אחרון',
			level_cert: 'דירוג הסמכה',
			"next": "הַבָּא",
			"skip": "לדלג",
			"previous": "קודם",
			"transport_survey": "Transportation Survey",
			"human_survey": "Human Experience Survey",
			"submit_both": "שלח אחד או את שניהם מהבאים",
			"Number of days in a week, you use this route": "מספר הימים בשבוע, אתה משתמש במסלול זה",
			"day": "יְוֹם",
			"visitorRouteError": "מבקרים יכולים להוסיף מסלול אחד בלבד."
		}
	};

	selected_language: string = this.getURLFromParam('language');

	constructor(
		private appData: AppData,
		private translate: TranslateService
	)
	{
		if(this.appData.get('current_app') == 'plaque')
        {
            this.languages=[
                {label:"English", symbol:"english", id:1, code:'gb'},
                {label:"French", symbol:"french", id:2, code:'fr'}
            ];
        }
        else
        {
            this.languages=[
                {label:"French", symbol:"french", id:2, code:'fr'},
                {label:"Portuguese", symbol:"portuguese", id:3, code:'pt'},
                {label:"German", symbol:"german", id:4, code:'de'},
                {label:"Spanish", symbol:"spanish", id:5, code:'es'},
                {label:"Chinese", symbol:"chinese", id:6, code:'cn'},
                {label:"Swedish", symbol:"swedish", id:7, code:'sv'},
                {label:"Arabic", symbol:"arabic", id:8, code:'ar'},
                {label:"Finnish", symbol:"finnish", id:9, code:'fi'},
                {label:"Russian", symbol:"russian", id:10, code:'ru'},
                {label:"Italian", symbol:"italian", id:11, code:'it'},
                {label:"Turkish", symbol:"turkish", id:12, code:'tr'},
                {label:"Japanese", symbol:"japanese", id:13, code:'jp'},
                {label:"Korean", symbol:"korean", id:14, code:'ko'},
                {label:"Urdu", symbol:"urdu", id:15, code:'ur'},
                {label:"Greek", symbol:"greek", id:16, code:'gre'},
                {label:"Hebrew", symbol:"hebrew", id:17, code:'heb'}
            ];
            this.languages = this.languages.sort((a: any,b: any) =>
            {
                return a["label"].localeCompare(b["label"]);
            });
			this.languages.unshift({label:"English", symbol:"english", id:1, code:'gb'});
        }

		//set default
        this.setLanguage(this.languages[0]);
        if(this.selected_language != undefined)
        {
            for(var i = 0; i < this.languages.length; i++)
            {
                if(this.languages[i].code == this.selected_language)
                {
                    this.setLanguage(this.languages[i]);
                    break;
                }
            }
        }

        if(this.selected_language != undefined)
        {
            for(var i = 0; i < this.languages.length; i++)
            {
                if(this.languages[i].code == this.selected_language)
                {
                    this.setLanguage(this.languages[i]);
                    break;
                }
            }
        }
	};

	initLanguage()
	{
		if(this.getURLFromParam('user_type') == 'arc_japan' || this.appData.get('user_type') == 'arc_japan' || this.appData.get('language') == 'ja')
		{
			this.appData.set('language', 'ja');
			this.appData.set('currency', '¥');
			this.appData.set('user_type', 'arc_japan')
			this.translate.setDefaultLang('ja');
			this.translate.use('ja');
		}
		else if(this.appData.get('language') != 'ja')
		{
			this.appData.set('user_type', 'arc')
			this.appData.set('currency', '$');
			this.appData.set('language', 'en');
		}
	}

	getTranslation(key: string)
	{
		return this.translate.get(key);
	}

	getURLFromParam(sParam: string): any
	{
		var sPageURL = window.location.search.substring(1);
		var sURLVariables = sPageURL.split('&');
		for (var i = 0; i < sURLVariables.length; i++)
		{
			var sParameterName = sURLVariables[i].split('=');
			if(sParameterName[0] == sParam)
			{
				return decodeURI(sParameterName[1]);
			}
		}
	};

	getLanguages()
	{
		return this.languages;
	};

	setLanguage(lang: string)
	{
		this.language=lang;
	};

	getLanguage()
	{
		return this.language;
	};

	getText(symbol: string)
	{
		if(this.texts[this.language.symbol] == undefined)
		{
			return symbol
		}
		return this.texts[this.language.symbol][symbol];
	};
}
