import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

import { Global } from './global.service';
import { AppData } from './appdata.service';
import { API } from './api.service';

declare var platformApi: any;
import { Config } from '../models/env-vars.model';
import { firstValueFrom } from 'rxjs';
import { LoaderComponent } from '../components/loader/loader.component';
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';

@Injectable({
  providedIn: 'root'
})
export class AppBootstrapService
{
	resolve: any;
	componentRef: any;

	constructor(
		private auth0: AuthService,
		private global: Global,
		private api: API,
		private appData: AppData,
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector
	)
	{
		this.appData.deleteLocal('browser-tabs-lock-key-auth0.lock.getTokenSilently');
		try
		{
			platformApi.initialize();
		}
		catch(e)
		{
			console.log('platformApi init error')
		}
		console.log('Loading app constructor...');

		let headers: any = {
			"Content-Type": "application/json",
			"Ocp-Apim-Subscription-Key": Config.sub_key,
			"Cache-Control": "no-cache",
			"Cache-Pragma": "no-cache",
			"If-Modified-Since": String(new Date().getTime())
		};
		if(this.appData.get("token") != null)
		{
			headers["Authorization"] = "Bearer " + this.appData.get("token");
		}
		else if(this.global.getURLFromParam('authorization_token') != null || this.global.getURLFromParam('authorization_token') != undefined)
		{
			headers["Authorization"] = "Bearer " + this.global.getURLFromParam('authorization_token');
		}
		this.appData.set('headers', headers);
		Config.headers = headers;
		this.appData.set('user_type', this.global.getURLFromParam('user_type'));
	}

	load(): Promise<any>
	{
		return new Promise<void>((resolve, reject) =>
		{
			this.resolve = resolve;

			let page_status = this.checkPage();
			if(page_status == 'public_page' || page_status == 'public_login')
			{
				this.resolve();
			}
			else
			{
				this.getAuth0();
			}
		});
	}

	async getAuth0()
	{
		if(window.location.pathname.indexOf('survey') != -1)
		{
			Config.app_loader = false;
			this.resolve();
			return;
		}
		if(Config.auth_fetched > 0)
		{
			$('.logout_external').modal('show');
			return;
		}

		this.componentRef = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent).create(this.injector);
		this.appRef.attachView(this.componentRef.hostView);
		const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
		document.body.appendChild(domElem);

		let token_status = this.hasToken();

		if(token_status == true || token_status == 'init_middleware')
		{
			let idToken = this.getToken();
			this.validateUserType({
				"id_token": idToken
			}, false);
			return;
		}

		let app_type: any;

		if(window.location.pathname == '/admin')
		{
			app_type = "admin_tool";
		}
		else if(window.location.pathname == '/arc-japan-admin')
		{
			app_type = "arcjp_admin_tool";
		}
		else if(window.location.pathname == '/cagbc-admin')
		{
			app_type = "cagbc_review_admin";
		}

		Config.auth_fetched += 1;

		document.cookie = 'auth0.'+ Config.sso_client +'.is.authenticated' + '=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/';
		document.cookie = '_legacy_auth0.'+ Config.sso_client +'.is.authenticated' + '=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/';
		Config.isAuthenticated = await firstValueFrom(this.auth0.isAuthenticated$);
		console.log('authenticated', Config.isAuthenticated, Config.auth_fetched);

		if (!Config.isAuthenticated)
		{
			let deviceId = undefined;
			try
			{
				deviceId = platformApi.deviceId;
			}
			catch(e)
			{
				console.log("Platform API not loaded;");
			}
			let payload: any = {
				"redirect_uri": window.location.origin + "/login_middleware?next=" + window.location.pathname,
				"deviceId": deviceId
			};
			if(app_type != undefined)
			{
				payload["redirect_uri"] += "&app_type=" + app_type;
			}
			if(this.global.getURLFromParam('user_type') != undefined)
			{
				payload["redirect_uri"] += "&user_type=" + this.global.getURLFromParam('user_type');
			}
			this.auth0.loginWithRedirect(
			{
				authorizationParams: payload
			});
		}
		else
		{
			this.auth0.getAccessTokenSilently(
			{
				detailedResponse: true
			}).subscribe((token) =>
			{
				if(this.appData.get('user_type') == 'arc_japan')
				{
					this.initApp(token);
				}
				else
				{
					this.validateUserType(token, true);
				}
			},
			(error) =>
			{});
		}
	}

	validateUserType(token: any, initApp: boolean)
	{
		if(
			window.location.pathname.indexOf('ecostruxure/redirect') !== -1 &&
			this.global.getURLFromParam('code') != undefined
		) {
			this.appData.set('ecostruxureCode', this.global.getURLFromParam('code'));
			this.appData.set('ecostruxureState', this.global.getURLFromParam('state'));
		}
		this.appData.set('show_switchover_in_arc', false);
		let options = {
			headers:
			{
				"Content-Type": "application/json",
				"Ocp-Apim-Subscription-Key": Config.sub_key,
				"Authorization": "Bearer " + token.id_token
			}
		};
		this.api.get('/auth/user/application-routing/', 'json', options).subscribe(
			data =>
			{
				this.appData.set('show_switchover_in_arc', data.show_switchover_in_arc);
				if(data.is_arc_user)
				{
					if(initApp)
					{
						this.initApp(token);
					}
					else
					{
						this.resumeApp();
					}
				}
				else
				{
					window.open(Config.HUB_URL, '_self');
				}
			},
			error =>
			{

			}
		);
	}

	initApp(token: GetTokenSilentlyVerboseResponse)
	{
		this.appData.setLocal('jwt', token.id_token);
		this.validateJWT(token.id_token);
		this.appRef.detachView(this.componentRef.hostView);
		this.componentRef.destroy();
	}

	resumeApp()
	{
		this.resolve();
		this.appRef.detachView(this.componentRef.hostView);
		this.componentRef.destroy();
		this.global.initLoginMiddleware();
		Config.auth_fetched += 1;
	}

	validateJWT(token: string)
    {
		if(Config.jwt_loader)
		{
			return;
		}
		Config.jwt_loader = true;
        this.api.post('/auth/oauth0/jwt-verifier/', {"token": token}).subscribe(
            data =>
            {
				Config.jwt_loader = false;
				this.appData.setLocal('auth0', data);

				this.resolve();
				this.global.initLoginMiddleware();
            },
            error =>
            {
				Config.jwt_loader = false;
				this.appData.deleteLocal('jwt');
        		this.appData.deleteLocal('auth0');

				if(this.appData.get('current_app') != 'login')
				{
					this.global.goToApp('login');
				}
            }
        );
    }

	checkPage(): any
	{
		if(window.location.pathname == '/login' || window.location.pathname.indexOf('survey') != -1 || window.location.pathname == '/maintenance' || window.location.pathname.indexOf('plaque') != -1)
		{
			return 'public_page';
		}
		else if(window.location.pathname == '/admin' || window.location.pathname == '/arc-japan-admin' || window.location.pathname == '/cagbc-admin')
		{
			return 'public_login';
		}
	}

	getToken()
	{
		return this.appData.getLocal('jwt')
	}

	hasToken(): boolean | string
	{
		if(
			this.appData.getLocal('auth0') == undefined
		)
		{
			return false;
		}
		else if(
			this.appData.getLocal('auth0') != undefined &&
			new Date() < new Date(this.appData.getLocal('auth0').expires_in)
		)
		{
			return 'init_middleware'
		}
		else
		{
			return true;
		}
	}

	forceAuth()
	{
		this.appData.deleteLocal('auth0');
		this.appData.deleteLocal('jwt');
		this.getAuth0();
	}
}
